import { Selector, State } from "@ngxs/store";
import { LoadingState } from "@store/common/LoadingState";
import { concat, EMPTY, finalize, MonoTypeOperatorFunction, Observable, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { RestPage } from "@api/models/RestPage";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Pagination } from "@api/models/Pagination";
import { Study } from "@api/models/Study";
import { StudyHttpService } from "@api/servies/study-http.service";
import { patch, updateItem } from "@ngxs/store/operators";
import { StudyStateType } from "@api/models/enums/StudyStateType";
import { DatePeriod } from "@api/models/DatePeriod";
import { CountryData } from "@api/models/CountryData";

export interface StudyListStateModel {
  items: RestPage<Study>;
  selected?: Study;
  loading: LoadingState;
}

export const STUDY_LIST_STATE_DEFAULT: StudyListStateModel = {
  items: new RestPage<Study>(),
  loading: LoadingState.IDLE
}

@StateRepository()
@State<StudyListStateModel>({
  name: 'StudyList',
  defaults: STUDY_LIST_STATE_DEFAULT
})
@Injectable()
export class StudyListState extends NgxsDataRepository<StudyListStateModel> {

  constructor(private dataService: StudyHttpService) {
    super();
  }

  @Selector()
  static items(state: StudyListStateModel) {
    return state.items
  }

  @Selector()
  static selected(state: StudyListStateModel) {
    return state.selected
  }

  @Selector()
  static isLoading(state: StudyListStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @DataAction({cancelUncompleted: true})
  get(params: any, pagination: Pagination) {
    this.ctx.patchState({loading: LoadingState.LOADING})
    return this.dataService.getAll(params, pagination).pipe(
      this.loadingIdleFinalize,
      tap((items: RestPage<Study>) => this.patchState({items}))
    )
  }

  @DataAction()
  delete(id: number) {
    this.ctx.patchState({loading: LoadingState.LOADING})
    return this.dataService.delete(id).pipe(
      this.loadingIdleFinalize
    )
  }

  @DataAction()
  cancel(id: number, cancelReason: string) {
    return this.dataService.patch({id, state: StudyStateType.CANCELED, cancelReason}).pipe(
      tap(study => this.updateItem(study))
    )
  }

  @DataAction()
  setSelected(selected: Study) {
    this.ctx.patchState({selected})
  }

  @DataAction()
  updateItem(study: Study) {
    this.ctx.setState(patch({
      items: patch({
        content: updateItem<Study>(
          item => item?.id === study.id,
          study
        )
      })
    }))
  }

  @DataAction()
  patchPeriod(id: number, datePeriod: DatePeriod) {
    return this.dataService.patchPeriod(id, datePeriod).pipe(
      this.loadingIdleFinalize,
      tap(study => this.updateItem(study))
    )
  }

  @DataAction()
  patchCountriesData(studyId: number, countriesData: CountryData) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.patchCountryData(studyId, countriesData).pipe(
      this.loadingIdleFinalize,
      tap(study => this.updateItem(study))
    )
  }

  private loadingIdleFinalize: MonoTypeOperatorFunction<any> = finalize(() => {
    this.patchState({loading: LoadingState.IDLE})
  })
}
