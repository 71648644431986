import { Partner } from "@api/models/Partner";
import { Selector, State } from "@ngxs/store";
import { PartnerHttpService } from "@api/servies/partner-http.service";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, MonoTypeOperatorFunction, of, switchMap, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { Utils } from "@utils/Utils";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { User } from "@api/models/User";
import { patch, updateItem } from "@ngxs/store/operators";


export interface PartnerStateModel {
  item: Partner;
  loading: LoadingState;
  newAvatar?: string;
}

export const PARTNER_STATE_DEFAULT: PartnerStateModel = {
  item: new Partner(),
  loading: LoadingState.IDLE
}

@StateRepository()
@State<PartnerStateModel>({
  name: 'Partner',
  defaults: PARTNER_STATE_DEFAULT
})
@Injectable()
export class PartnerState extends NgxsDataRepository<PartnerStateModel>{

  constructor(private dataService: PartnerHttpService) {
    super();
  }

  @Selector()
  static item(state: PartnerStateModel) {
    return state.item
  }

  @Selector()
  static isLoading(state: PartnerStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @Selector()
  static isSaving(state: PartnerStateModel) {
    return state.loading === LoadingState.SAVING;
  }

  @Selector()
  static avatar(state: PartnerStateModel) {
    return state.newAvatar || state.item.imageUrl;
  }

  @DataAction({ cancelUncompleted: true })
  get(id: number) {
    this.ctx.patchState({...this.initialState, loading: LoadingState.LOADING})
    return this.dataService.getById(id).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  create(item: Partner) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.create(item).pipe(
      this.loadingIdleFinalize,
      this.updateAvatarSwitchMap,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  update(item: Partner) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.update(item).pipe(
      this.loadingIdleFinalize,
      this.updateAvatarSwitchMap,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  changeAvatar(avatar: string) {
    this.ctx.patchState({newAvatar: avatar})
  }

  @DataAction()
  uploadAvatar(avatar: string) {
    this.ctx.patchState({newAvatar: avatar})
    const state = this.ctx.getState()
    const formData = new FormData()
    formData.append('file', Utils.dataURItoBlob(avatar))
    return this.dataService.updateImage(state.item.id as number, formData).pipe(this.requestSuccessTap)
  }

  @DataAction()
  deleteUser(userId: number, partnerId: number) {
    return this.dataService.deleteUser(userId, partnerId).pipe(
      tap((user: User) => this.setState(patch({item: patch({users: updateItem<User>(u => u?.id === user.id, user)})})))
    )
  }

  private requestSuccessTap: MonoTypeOperatorFunction<Partner> = tap((item: Partner) => this.patchState({item, newAvatar: undefined}))

  private loadingIdleFinalize: MonoTypeOperatorFunction<Partner> = finalize(() => {this.patchState({loading: LoadingState.IDLE})})

  private updateAvatarSwitchMap: MonoTypeOperatorFunction<Partner> = switchMap((item: Partner) => {
    const state = this.ctx.getState()
    if (state.newAvatar) {
      const formData = new FormData();
      formData.append('file', Utils.dataURItoBlob(state.newAvatar));
      return this.dataService.updateImage(item.id as number, formData)
    } else {
      return of(item);
    }
  })

}
