import { Selector, State } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { patch } from "@ngxs/store/operators";
import { NavigationSearchParams } from '@models/NavigationSearchParams';

export interface NavigationExtrasModel {
  navigationSearchParams: NavigationSearchParams;
}

export const NAVIGATION_EXTRAS_STATE_DEFAULT: NavigationExtrasModel = {
  navigationSearchParams: {}
}

@StateRepository()
@State<NavigationExtrasModel>({
  name: 'NavigationExtras',
  defaults: NAVIGATION_EXTRAS_STATE_DEFAULT
})
@Injectable()
export class NavigationExtrasState extends NgxsDataRepository<NavigationExtrasModel>{

  constructor() {
    super();
  }

  @Selector()
  static navigationSearchParams(state: NavigationExtrasModel) {
    return state.navigationSearchParams
  }

  @DataAction()
  setNavigationSearchParams(navigationSearchParams: NavigationSearchParams) {
    this.ctx.setState(patch({ navigationSearchParams }))
  }

  @DataAction()
  addNavigationSearchParams(params: NavigationSearchParams) {
    const navigationSearchParams = { ...this.ctx.getState().navigationSearchParams, ...params }
    this.ctx.setState(patch({ navigationSearchParams }))
  }

  @DataAction()
  resetNavigationSearchParams() {
    this.ctx.setState(patch({ navigationSearchParams: NAVIGATION_EXTRAS_STATE_DEFAULT.navigationSearchParams }))
  }

}
