import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Utils } from "@utils/Utils";

@Injectable({providedIn: 'root'})
export class SnackBarService {

  constructor(private messageService: MessageService) {
  }

  show(severity: "success" | "info" | "warn" | "error", summary: string, detail?: string) {
    return this.messageService.add({severity: severity, summary: summary, detail: detail, life: 5000});
  }

  showSuccess(summary: string, detail?: string) {
    this.show("success", summary, detail);
  }

  showInfo(summary: string, detail?: string) {
    this.show("info", summary, detail);
  }

  showWarning(summary: string, detail?: string) {
    this.show("warn", summary, detail);
  }

  showError(summary: string, detail?: string) {
    this.show("error", summary, detail);
  }

  showHttpError(error: any) {
    this.showError(Utils.getErrorMessage(error));
  }

  handleHttpError = (error: any) => this.showHttpError(error);

  showItemSaveSuccess(entityName: string, updated: boolean) {
    this.show("info", `${entityName} ${updated ? 'updated' : 'created' } successfully`);
  }

  methodNotImplemented() {
    return this.showError("Not Implemented");
  }

}
