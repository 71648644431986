import { Amount } from "@api/models/Amount";
import { Duration } from "@api/models/Duration";
import { Tax } from "@api/models/Tax";
import { Country } from "@api/models/Country";
import { Requirement } from "@api/models/Requirement";

export class Rule {
  id?: number
  country: Country = new Country()
  perClaimLimit: Amount = new Amount()
  aggregatedLimit: Amount = new Amount()
  erp: Duration = new Duration()
  taxes: Tax[] = []
  requirements: Requirement[] = []
  remarks: string[] = []
  wetInkEnabled: boolean = false
  travelInsuranceEnabled: boolean = false
}
