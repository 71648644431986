import { Selector, State, StateOperator } from "@ngxs/store";
import { LoadingState } from "@store/common/LoadingState";
import { concat, EMPTY, finalize, MonoTypeOperatorFunction, Observable, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, Persistence, StateRepository } from "@angular-ru/ngxs/decorators";
import { Drug, MedicalProduct, Study, StudyDetail } from "@api/models/Study";
import { StudyHttpService } from "@api/servies/study-http.service";
import { StudyType } from "@api/models/enums/StudyType";
import { patch, removeItem, updateItem } from "@ngxs/store/operators";
import { CountryData } from "@api/models/CountryData";
import { StudyStateType } from "@api/models/enums/StudyStateType";
import { StudyFormValidationType } from "@models/enums/StudyFormValidationType";
import { StudyCountryStateType } from "@api/models/enums/StudyCountryStateType";
import { DatePeriod } from "@api/models/DatePeriod";
import { CustomCountryCoverage } from "@api/models/CustomCountryCoverage";
import { AmendmentCreate } from "@api/models/AmendmentCreate";


export interface StudyStateModel {
  formStep: number;
  activeCountryAccordionIndex?: number;
  loading: LoadingState;
  item: Study;
  currentValidation: StudyFormValidationType;
}

export const STUDY_STATE_DEFAULT: StudyStateModel = {
  formStep: 1,
  activeCountryAccordionIndex: undefined,
  loading: LoadingState.IDLE,
  item: new Study(),
  currentValidation: StudyFormValidationType.SAVING,
}

@Persistence({ path: 'Study.formStep', existingEngine: localStorage })
@StateRepository()
@State<StudyStateModel>({
  name: 'Study',
  defaults: STUDY_STATE_DEFAULT
})
@Injectable()
export class StudyState extends NgxsDataRepository<StudyStateModel> {

  constructor(private dataService: StudyHttpService) {
    super();
  }

  @Selector()
  static item(state: StudyStateModel) {
    return state.item
  }

  @Selector()
  static step(state: StudyStateModel) {
    return state.formStep
  }

  @Selector()
  static activeCountryAccordionIndex(state: StudyStateModel) {
    return state.activeCountryAccordionIndex
  }

  @Selector()
  static isIdle(state: StudyStateModel) {
    return state.loading === LoadingState.IDLE;
  }

  @Selector()
  static isLoading(state: StudyStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @Selector()
  static isSaving(state: StudyStateModel) {
    return state.loading === LoadingState.SAVING;
  }

  @Selector()
  static currentValidation(state: StudyStateModel) {
    return state.currentValidation;
  }

  @Selector()
  static selectedCountries(state: StudyStateModel) {
    return state.item.countriesData?.map(countryData => countryData.country) || []
  }

  @Selector()
  static isReadOnly(state: StudyStateModel) {
    return !StudyStateType.isActive(state.item.state)
  }

  @Selector()
  static isDetailReadOnly(state: StudyStateModel) {
    return !StudyStateType.isPreparing(state.item.state)
  }

  @Selector()
  static state(state: StudyStateModel) {
    return state.item.state
  }

  @Selector()
  static hasDraftCountries(state: StudyStateModel) {
    return state.item.countriesData.some(data => data.state === StudyCountryStateType.DRAFT)
  }

  @Selector()
  static hasSubmittableCountries(state: StudyStateModel) {
    return state.item.countriesData.some(data => data.state === StudyCountryStateType.DRAFT && data.testPersons && data.randomizedTestPersons)
  }

  @DataAction()
  setItem(item: Study) {
    this.ctx.setState(patch({ item: patch({
        type: item.type,
        countriesData: item.countriesData,
        sponsor: item.sponsor,
        studyId: item.studyId,
        phase: item.phase,
        detail: item.detail
    })}))
  }

  @DataAction({cancelUncompleted: true})
  get(id: number) {
    this.ctx.patchState({
      ...this.initialState,
      loading: LoadingState.LOADING,
      formStep: this.snapshot.formStep,
      activeCountryAccordionIndex: this.snapshot.activeCountryAccordionIndex
    })
    return this.dataService.getById(id).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap
    )
  }

  @DataAction()
  create(item: Study) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.create(item).pipe(
      this.loadingIdleFinalize,
      tap((item: Study) => this.patchState({item})),
    )
  }

  @DataAction()
  updateDetail(id: number, detail: StudyDetail) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.updateDetail(id, detail).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  submit(id: number, targetInsurances: number[]) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.patch({id, state: StudyStateType.SUBMITTED, targetInsurances}).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  resubmit(id: number) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.resubmit(id).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  cancel(id: number, cancelReason: string) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.patch({id, state: StudyStateType.CANCELED, cancelReason}).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  upsertCountryData(countryData: CountryData) {
    if (!countryData.wetInkData.wetInkRecipientAddress.country) {
      countryData.wetInkData.wetInkRecipientAddress.country = countryData.country.code
    }
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.upsertCountryData(this.getState().item.id, countryData).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  upsertCountriesData(countriesData: CountryData[]) {
    countriesData.forEach(countryData => {
      countryData.wetInkData.wetInkRecipientAddress.country = countryData.country.code
    })
    if (!countriesData.length) return EMPTY;
    this.ctx.patchState({loading: LoadingState.SAVING})
    let observables: Observable<Study>[] = countriesData.map(countryData => this.dataService.upsertCountryData(this.getState().item.id, countryData));
    return concat(...observables).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  deleteCountryData(countryCode: string) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.deleteCountryData(this.getState().item.id, countryCode).pipe(
      this.loadingIdleFinalize,
      tap(() => this.setState(patch({ item: patch( { countriesData: removeItem<CountryData>(data => data?.country.code === countryCode) }) })))
    )
  }

  @DataAction()
  updateCountryDataByRules(customCountriesCoverages: CustomCountryCoverage[]) {
    this.setState(patch({
      item: patch( { countriesData: patchCountryDataByCustomCoverage(customCountriesCoverages) })
    }))
  }

  @DataAction()
  updateCountryDataByCustomCoverage(customCountriesCoverage: CustomCountryCoverage) {
    this.setState(patch({
      item: patch({
        countriesData: updateItem<CountryData>(
          data => data?.country.code === customCountriesCoverage.country.code,
          patch({...customCountriesCoverage, coverageCustomized: true})
        )
      })
    }))
  }

  @DataAction()
  cancelCountryData(countryData: CountryData) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.cancelCountryData(this.getState().item.id, countryData).pipe(
      this.loadingIdleFinalize,
      tap(() => this.setState(patch({
        item: patch({countriesData: updateItem<CountryData>(data => data?.country.code === countryData.country.code, patch({state: StudyCountryStateType.CANCELED}))})
      })))
    )
  }

  @DataAction()
  withdrawOffer(studyId: number, offerId: number) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.withdrawOffer(studyId, offerId).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  uploadStudyProtocol(file: File) {
    this.ctx.setState(resetStudyProtocol());
    const formData = new FormData();
    formData.append('file', file);
    return this.dataService.uploadStudyProtocol(this.snapshot.item.id as number, formData).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  deleteStudyProtocol() {
    this.ctx.setState(resetStudyProtocol());
    return this.dataService.deleteStudyProtocol(this.snapshot.item.id as number).pipe(
      this.loadingIdleFinalize
    )
  }

  @DataAction()
  uploadPatientInfoProtocol(file: File) {
    this.ctx.setState(resetPatientInfo());
    const formData = new FormData();
    formData.append('file', file);
    return this.dataService.uploadPatientInfo(this.snapshot.item.id as number, formData).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  deletePatientInfo() {
    this.ctx.setState(resetPatientInfo());
    return this.dataService.deletePatientInfo(this.snapshot.item.id as number).pipe(
      this.loadingIdleFinalize
    )
  }

  @DataAction()
  patchPeriod(id: number, datePeriod: DatePeriod) {
    return this.dataService.patchPeriod(id, datePeriod).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  removeCountryData(countryCode: string) {
    this.ctx.setState(patch({
      item: patch(
        {countriesData: removeItem<CountryData>(data => data?.country.code === countryCode)})
    }));
  }

  @DataAction()
  uploadAmendment(id: number, amendmentCreate: AmendmentCreate) {
    const formData = new FormData();
    formData.append('file', amendmentCreate.file);
    let amendment: Partial<AmendmentCreate> = {...amendmentCreate};
    delete amendment.file;
    delete amendment.sequence;
    formData.append('amendment', new Blob([JSON.stringify(amendment)], { type: "application/json" }));
    return this.dataService.uploadAmendment(id, amendmentCreate.sequence, formData).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  prepareForSubmit(targetInsuranceIds: number[]) {
    this.ctx.setState(prepareForSubmit(targetInsuranceIds));
  }

  @DataAction()
  setStep(formStep: number) {
    this.ctx.patchState({formStep: formStep})
  }

  @DataAction()
  setActiveCountryAccordionIndex(index?: number) {
    this.ctx.patchState({activeCountryAccordionIndex: index})
  }

  @DataAction()
  nextStep() {
    const state = this.ctx.getState()
    this.ctx.patchState({formStep: state.formStep + 1})
  }

  @DataAction()
  previousStep() {
    const state = this.ctx.getState()
    this.ctx.patchState({formStep: state.formStep - 1})
  }

  @DataAction()
  toggleFormValidation() {
    const state = this.ctx.getState()
    this.ctx.patchState({
      currentValidation: state.currentValidation === StudyFormValidationType.SUBMISSION
        ? StudyFormValidationType.SAVING
        : StudyFormValidationType.SUBMISSION
    })
  }

  @DataAction()
  setFormValidation(validationType: StudyFormValidationType) {
    this.ctx.patchState({ currentValidation: validationType })
  }

  setType(type: StudyType) {
    if (type === StudyType.DRUG) {
      this.ctx.setState(patch({item: patch({type: type as StudyType, drug: new Drug()})}))
    } else if (type === StudyType.MEDICAL_PRODUCT) {
      this.ctx.setState(patch({item: patch({type: type as StudyType, medicalProduct: new MedicalProduct()})}))
    }
  }

  patchCountriesData(countriesData: CountryData[]) {
    this.ctx.setState(patch({
      item: patch({countriesData: countriesData})
    }));
  }

  private requestSuccessTap: MonoTypeOperatorFunction<Study> = tap((item: Study) => this.patchState({item}))

  private loadingIdleFinalize: MonoTypeOperatorFunction<any> = finalize(() => {
    this.patchState({loading: LoadingState.IDLE})
  })
}

function patchCountryDataByCustomCoverage(customCountriesCoverages: CustomCountryCoverage[]): StateOperator<CountryData[]> {
  return (state: Readonly<CountryData[]>) => {
    return state.map(countryData => {
      return {...countryData, ...customCountriesCoverages.find(c => c.country.code === countryData.country.code)}
    });
  };
}

function resetStudyProtocol(): StateOperator<StudyStateModel> {
  return (state: Readonly<StudyStateModel>) => {
    return {
      ...state,
      loading: LoadingState.SAVING,
      item: {
        ...state.item,
        studyProtocol: undefined
      }
    };
  };
}

function resetPatientInfo(): StateOperator<StudyStateModel> {
  return (state: Readonly<StudyStateModel>) => {
    return {
      ...state,
      loading: LoadingState.SAVING,
      item: {
        ...state.item,
        patientInfo: undefined
      }
    };
  };
}

function prepareForSubmit(targetInsurances: number[] = []): StateOperator<StudyStateModel> {
  return (state: Readonly<StudyStateModel>) => {
    return {
      ...state,
      item: {
        ...state.item,
        targetInsurances,
        state: StudyStateType.SUBMITTED
      }
    };
  };
}

