export enum StudyStateType {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  QUOTED = 'QUOTED',
  SIGNED = 'SIGNED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export namespace StudyStateType {
  export function getClosedStates() {
    return [StudyStateType.CANCELED, StudyStateType.COMPLETED];
  }

  export function isActive(state: StudyStateType) {
    return !getClosedStates().includes(state);
  }

  export function isPreparing(state: StudyStateType) {
    return ![...getClosedStates(), StudyStateType.SIGNED].includes(state);
  }
}

export enum InsurerStudyStateType {
  NEW = 'NEW',
  QUOTED = 'QUOTED',
  SIGNED = 'SIGNED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}
