import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { API_URL } from "@utils/Constants";
import { CustomHttpParameterCodec } from "@utils/CustomHttpParameterCodec";
import { Pagination } from "@api/models/Pagination";
import { RestPage } from "@api/models/RestPage";
import { Observable } from "rxjs";
import { Study, StudyDetail } from "@api/models/Study";
import { Offer } from "@api/models/Offer";
import { StudyPatchRequest } from "@api/models/StudyPatchRequest";
import { CountryData } from "@api/models/CountryData";
import { DatePeriod } from "@api/models/DatePeriod";
import { StringWrapper } from "@api/models/StringWrapper";


@Injectable({ providedIn: 'root' })
export class StudyHttpService {
  private readonly URL = `${API_URL}/studies`;

  constructor(private http: HttpClient) { }

  checkStudyIdUniqueness(studyId: string) {
    return this.http.head<any>(`${this.URL}`, { params: { studyId } });
  }

  getAll(params: any, pagination: Pagination = new Pagination()): Observable<RestPage<Study>> {
    params = new HttpParams({ fromObject: Object.assign(params, pagination), encoder: new CustomHttpParameterCodec });
    return this.http.get<RestPage<Study>>(`${this.URL}`, { params });
  }

  getById(id: number): Observable<Study> {
    return this.http.get<Study>(`${this.URL}/${id}`);
  }

  create(study: Study): Observable<Study> {
    return this.http.post<Study>(`${this.URL}`, study);
  }

  patch(patch: StudyPatchRequest): Observable<Study> {
    return this.http.patch<Study>(`${this.URL}/${patch.id}`, patch);
  }

  resubmit(id: number): Observable<Study> {
    return this.http.patch<Study>(`${this.URL}/${id}/resubmit`,{});
  }

  patchPeriod(id: number, datePeriod: DatePeriod): Observable<Study> {
    return this.http.patch<Study>(`${this.URL}/${id}/period`, datePeriod);
  }

  delete(id: number): Observable<Study> {
    return this.http.delete<Study>(`${this.URL}/${id}`);
  }

  updateDetail(id: number, studyDetail: StudyDetail): Observable<Study> {
    return this.http.put<Study>(`${this.URL}/${id}/detail`, studyDetail);
  }

  uploadStudyProtocol(id: number, formData: FormData): Observable<Study> {
    return this.http.put<Study>(`${this.URL}/${id}/studyProtocol`, formData);
  }

  deleteStudyProtocol(id: number) {
    return this.http.delete<Study>(`${this.URL}/${id}/studyProtocol`);
  }

  uploadPatientInfo(id: number, formData: FormData): Observable<Study> {
    return this.http.put<Study>(`${this.URL}/${id}/patientInfo`, formData);
  }

  deletePatientInfo(id: number) {
    return this.http.delete<Study>(`${this.URL}/${id}/patientInfo`);
  }

  upsertCountryData(studyId: number, countryData: CountryData) {
    return this.http.put<Study>(`${this.URL}/${studyId}/countries/${countryData.country.code}`, countryData);
  }

  patchCountryData(studyId: number, countryData: CountryData) {
    return this.http.patch<Study>(`${this.URL}/${studyId}/countries/${countryData.country.code}`, countryData);
  }

  updateWetInkDeliveryInfo(studyId: number, countryCode: string, comment: string) {
    return this.http.put<Study>(`${this.URL}/${studyId}/countries/${countryCode}/wetInkData/deliveryInfo`, new StringWrapper(comment));
  }

  deleteCountryData(studyId: number, countryCode: string) {
    return this.http.delete<CountryData>(`${this.URL}/${studyId}/countries/${countryCode}`);
  }

  cancelCountryData(studyId: number, countryData: CountryData) {
    return this.http.patch<CountryData>(`${this.URL}/${studyId}/countries/${countryData.country.code}`, countryData);
  }

  upsertOffer(studyId: number, offer: Offer) {
    return this.http.post<Offer>(`${this.URL}/${studyId}/offers`, offer);
  }

  getOffer(studyId: number, offerId: number) {
    return this.http.get<Offer>(`${this.URL}/${studyId}/offers/${offerId}`);
  }

  getOffers(studyId: number) {
    return this.http.get<Offer[]>(`${this.URL}/${studyId}/offers`);
  }

  withdrawOffer(studyId: number, offerId: number) {
    return this.http.delete<Study>(`${this.URL}/${studyId}/offers/${offerId}`);
  }

  requestOfferItemProlongation(studyId: number, offerId: number, countryCode: string) {
    return this.http.post<void>(`${this.URL}/${studyId}/offers/${offerId}/items/${countryCode}/prolongationRequest`, {});
  }

  uploadAmendment(id: number, sequence: number, formData: FormData): Observable<Study> {
    return this.http.post<Study>(`${this.URL}/${id}/amendments/${sequence}/document`, formData);
  }

  downloadDocuments(id: number): Observable<any> {
    const httpOptions = {responseType: 'blob' as 'json', observe: 'response' as any};
    return this.http.get<HttpResponse<Blob>>(`${this.URL}/${id}/documents`, httpOptions)
  }

}
