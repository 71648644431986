import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from '@angular/core';
import { COUNTRY_LIST, Country } from "@api/models/Country";

@Pipe({ name: 'countryCodeToName' })
export class CountryCodeToNamePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return '';
    return (
      COUNTRY_LIST.find((item: Country) => item.code === value)?.name || ''
    );
  }
}

@NgModule({
  declarations: [CountryCodeToNamePipe],
  exports: [CountryCodeToNamePipe],
})
export class CountryCodeToNamePipeModule {
  static forRoot(): ModuleWithProviders<CountryCodeToNamePipeModule> {
    return {
      ngModule: CountryCodeToNamePipeModule,
      providers: [CountryCodeToNamePipe]
    };
  }
}
