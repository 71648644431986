import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API_URL } from "@utils/Constants";
import { CustomHttpParameterCodec } from "@utils/CustomHttpParameterCodec";
import { Pagination } from "@api/models/Pagination";
import { RestPage } from "@api/models/RestPage";
import { Partner } from "@api/models/Partner";
import { Observable } from "rxjs";
import { User } from "@api/models/User";

@Injectable({providedIn: 'root'})
export class PartnerHttpService {
  private readonly URL = `${API_URL}/partners`;

  constructor(private http: HttpClient) {
  }

  getAll(params: any, pagination: Pagination = new Pagination()): Observable<RestPage<Partner>> {
    params = new HttpParams({fromObject: Object.assign(params, pagination), encoder: new CustomHttpParameterCodec});
    return this.http.get<RestPage<Partner>>(`${this.URL}`, {params});
  }

  getById(id: number): Observable<Partner> {
    return this.http.get<Partner>(`${this.URL}/${id}`);
  }

  create(partner: Partner): Observable<Partner> {
    return this.http.post<Partner>(`${this.URL}`, partner);
  }

  update(partner: Partner): Observable<Partner> {
    return this.http.put<Partner>(`${this.URL}/${partner.id}`, partner);
  }

  updateImage(id: number, formData: FormData): Observable<Partner> {
    return this.http.put<Partner>(`${this.URL}/${id}/image`, formData);
  }

  getUsers(partnerId: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.URL}/${partnerId}/users`);
  }

  getUser(id: number, partnerId: number): Observable<User> {
    return this.http.get<User>(`${this.URL}/${partnerId}/users/${id}`);
  }

  createUser(user: User, partnerId: number): Observable<User> {
    return this.http.post<User>(`${this.URL}/${partnerId}/users`, user);
  }

  updateUser(user: User, partnerId: number): Observable<User> {
    return this.http.put<User>(`${this.URL}/${partnerId}/users/${user.id}`, user);
  }

  updateOwn(user: User, partnerId: number): Observable<User> {
    return this.http.put<User>(`${this.URL}/${partnerId}/users/my`, user);
  }

  deleteUser(id: number, partnerId: number): Observable<User> {
    return this.http.delete<User>(`${this.URL}/${partnerId}/users/${id}`);
  }

  updateUserImage(id: number, partnerId: number, formData: FormData): Observable<User> {
    return this.http.put<User>(`${this.URL}/${partnerId}/users/${id}/image`, formData);
  }

  getMy(): Observable<Partner> {
    return this.http.get<Partner>(`${this.URL}/my`);
  }

}
