import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from "@angular/core";
import { getCurrencySymbol } from "@angular/common";

@Pipe({ name: 'currencySymbol', pure: true })
export class CurrencySymbolPipe implements PipeTransform {
  transform(currencyCode: string, format: 'wide' | 'narrow' = 'narrow', locale?: string): any {
    return getCurrencySymbol(currencyCode, format, locale);
  }
}

@NgModule({
  declarations: [CurrencySymbolPipe],
  exports: [CurrencySymbolPipe],
})
export class CurrencySymbolPipeModule {
  static forRoot(): ModuleWithProviders<CurrencySymbolPipeModule> {
    return {
      ngModule: CurrencySymbolPipeModule,
      providers: [CurrencySymbolPipe]
    };
  }
}
