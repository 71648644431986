export enum ErrorStatusCodeType {
  NOT_FOUND = 404,
  VALIDATION = 422,
  FORBIDDEN = 403,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
}

export enum ErrorCodeType {
  UNEXPECTED_CLIENT_ERROR = 'UNEXPECTED_CLIENT_ERROR',
}
