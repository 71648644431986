import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from "@shared/shared.module";
import { LayoutMainComponent } from "./layout-main/layout-main.component";
import { LayoutAdminComponent } from "./layout-admin/layout-admin.component";
import { LayoutDefaultComponent } from './layout-default/layout-default.component';
import { NgTruncatePipeModule } from "angular-pipes";
import { NotificationsPopupComponent } from './header/notifications-popup/notifications-popup.component';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TimeagoModule } from "ngx-timeago";
import { ScrollPanelModule } from "primeng/scrollpanel";


@NgModule({
    imports: [
        SharedModule.forRoot(),
        NgTruncatePipeModule,
        OverlayPanelModule,
        TimeagoModule.forRoot(),
        ScrollPanelModule,
    ],
  declarations: [
    HeaderComponent,
    LayoutMainComponent,
    LayoutAdminComponent,
    LayoutDefaultComponent,
    NotificationsPopupComponent,
  ],
  exports: [
    HeaderComponent,
    LayoutMainComponent,
    LayoutAdminComponent
  ]
})
export class LayoutModule {

}
