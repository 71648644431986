import { Injectable } from "@angular/core";
import { API_URL } from "@utils/Constants";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { map, of } from "rxjs";
import { ErrorStatusCodeType } from "@models/enums/ErrorStatusCodeType";

@Injectable({providedIn: 'root'})
export class UserHttpService {
  private readonly URL = `${API_URL}/users`;

  constructor(private http: HttpClient) {
  }

  public isEmailOccupied(userEmail: string) {
    return this.http.get<any>(`${this.URL}/${userEmail}`).pipe(
      map(() => true),
      catchError(error => {
        if (!(error.error instanceof ErrorEvent)) {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          if (error.status === ErrorStatusCodeType.NOT_FOUND) {
            return of(false);
          }
        }
        return of(true);
      }),
    );
  }
}
