import { Selector, State } from "@ngxs/store";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, MonoTypeOperatorFunction, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { RestPage } from "@api/models/RestPage";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Pagination } from "@api/models/Pagination";
import { patch, updateItem } from "@ngxs/store/operators";
import { Contract } from "@api/models/Contract";
import { ContractHttpService } from "@api/servies/contract-http.service";
import { ContractItem } from "@api/models/ContractItem";

export interface ContractListStateModel {
  items: RestPage<Contract>;
  selected?: Contract;
  loading: LoadingState;
}

export const CONTRACT_LIST_STATE_DEFAULT: ContractListStateModel = {
  items: new RestPage<Contract>(),
  loading: LoadingState.IDLE
}

@StateRepository()
@State<ContractListStateModel>({
  name: 'ContractList',
  defaults: CONTRACT_LIST_STATE_DEFAULT
})
@Injectable()
export class ContractListState extends NgxsDataRepository<ContractListStateModel> {

  constructor(private dataService: ContractHttpService) {
    super();
  }

  @Selector()
  static items(state: ContractListStateModel) {
    return state.items
  }

  @Selector()
  static selected(state: ContractListStateModel) {
    return state.selected
  }

  @Selector()
  static isLoading(state: ContractListStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @DataAction({cancelUncompleted: true})
  get(params: any, pagination: Pagination) {
    this.ctx.patchState({loading: LoadingState.LOADING})
    return this.dataService.getAll(params, pagination).pipe(
      this.loadingIdleFinalize,
      tap((items: RestPage<Contract>) => this.patchState({items}))
    )
  }

  @DataAction()
  setSelected(selected: Contract) {
    this.ctx.patchState({selected})
  }

  @DataAction()
  updateItem(contract: Contract) {
    this.ctx.setState(patch({
      items: patch({
        content: updateItem<Contract>(
          item => item?.id === contract.id,
          contract
        )
      })
    }))
  }

  @DataAction()
  patchPaymentState(contract: Contract) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.patchPaymentState(contract.id, contract).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  private loadingIdleFinalize: MonoTypeOperatorFunction<any> = finalize(() => {
    this.patchState({loading: LoadingState.IDLE})
  })

  private requestSuccessTap: MonoTypeOperatorFunction<Contract> = tap(contract => {
    this.ctx.setState(patch({
      items: patch({
        content: updateItem<Contract>(
          item => item?.id === contract.id,
          contract
        )
      })
    }))
  })

}
