import { Selector, State, StateOperator } from "@ngxs/store";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, MonoTypeOperatorFunction, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, Persistence, StateRepository } from "@angular-ru/ngxs/decorators";
import { StudyHttpService } from "@api/servies/study-http.service";
import { Offer } from "@api/models/Offer";


export interface OfferStateModel {
  loading: LoadingState;
  item: Offer;
}

export const OFFER_STATE_DEFAULT: OfferStateModel = {
  loading: LoadingState.IDLE,
  item: new Offer()
}

@StateRepository()
@State<OfferStateModel>({
  name: 'Offer',
  defaults: OFFER_STATE_DEFAULT
})
@Injectable()
export class OfferState extends NgxsDataRepository<OfferStateModel> {

  constructor(private dataService: StudyHttpService) {
    super();
  }

  @Selector()
  static item(state: OfferStateModel) {
    return state.item
  }

  @Selector()
  static isLoading(state: OfferStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @Selector()
  static isSaving(state: OfferStateModel) {
    return state.loading === LoadingState.SAVING;
  }

  @DataAction({cancelUncompleted: true})
  get(studyId: number, id: number) {
    this.ctx.patchState({...this.initialState, loading: LoadingState.LOADING})
    return this.dataService.getOffer(studyId, id).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap
    )
  }

  @DataAction()
  upsert(studyId: number, item: Offer) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.upsertOffer(studyId, item).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap
    )
  }

  private requestSuccessTap: MonoTypeOperatorFunction<Offer> = tap((item: Offer) => this.patchState({item}))

  private loadingIdleFinalize: MonoTypeOperatorFunction<Offer> = finalize(() => this.patchState({loading: LoadingState.IDLE}))

}


