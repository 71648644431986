import { Directive, Input, NgModule, TemplateRef, ViewContainerRef } from "@angular/core";
import { CommonModule } from "@angular/common";


@Directive({
  selector: '[ngVar]',
})
export class VarDirective {
  @Input()
  set ngVar(context: unknown) {
    this.context.$implicit = this.context.ngVar = context;

    if (!this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef, this.context);
      this.hasView = true;
    }
  }

  private context: {
    $implicit: unknown;
    ngVar: unknown;
  } = {
    $implicit: null,
    ngVar: null,
  };

  private hasView: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef
  ) {}
}

@NgModule({
  declarations: [VarDirective],
  imports: [
    CommonModule
  ],
  exports: [VarDirective]
})
export class VarDirectiveModule { }
