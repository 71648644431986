import { Partner } from "@api/models/Partner";
import { Selector, State } from "@ngxs/store";
import { PartnerHttpService } from "@api/servies/partner-http.service";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { RestPage } from "@api/models/RestPage";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Pagination } from "@api/models/Pagination";
import { patch, updateItem } from "@ngxs/store/operators";

export interface PartnerListStateModel {
  items: RestPage<Partner>;
  selected?: Partner;
  loading: LoadingState;
}

export const PARTNER_LIST_STATE_DEFAULT: PartnerListStateModel = {
  items: new RestPage<Partner>(),
  loading: LoadingState.IDLE
}

@StateRepository()
@State<PartnerListStateModel>({
  name: 'PartnerList',
  defaults: PARTNER_LIST_STATE_DEFAULT
})
@Injectable()
export class PartnerListState extends NgxsDataRepository<PartnerListStateModel>{

  constructor(private dataService: PartnerHttpService) {
    super();
  }

  @Selector()
  static items(state: PartnerListStateModel) {
    return state.items
  }

  @Selector()
  static selected(state: PartnerListStateModel) {
    return state.selected
  }

  @Selector()
  static isLoading(state: PartnerListStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @DataAction({ cancelUncompleted: true })
  get(params: any, pagination: Pagination) {
    this.ctx.patchState({loading: LoadingState.LOADING})
    return this.dataService.getAll(params, pagination).pipe(
      finalize(() => { this.patchState({loading: LoadingState.IDLE})}),
      tap((items: RestPage<Partner>) => this.patchState({items}))
    )
  }

  @DataAction()
  setSelected(selected: Partner) {
    this.ctx.patchState({ selected })
  }

  @DataAction()
  update(item: Partner) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.update(item).pipe(
      finalize(() => { this.patchState({loading: LoadingState.IDLE})}),
      tap((updated: Partner) => this.setState(patch({ items: patch({ content: updateItem<Partner>(i => i?.id === updated.id, updated) }), selected: item})))
    )
  }

}
