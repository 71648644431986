import { Selector, State } from "@ngxs/store";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, switchMap, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { RestPage } from "@api/models/RestPage";
import { Notification } from "@api/models/Notification";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { NotificationHttpService } from "@api/servies/notification-http.service";
import { PaginationLazy } from "@api/models/PaginationLazy";
import { insertItem, patch } from "@ngxs/store/operators";
import { GetAllNotificationsParams } from "@api/models/params/GetAllNotificationsParams";

export interface NotificationListStateModel {
  lastPage: RestPage<Notification>;
  items: Notification[]
  selected?: Notification;
  loading: LoadingState;
  pagination: PaginationLazy,
  params: GetAllNotificationsParams,
  totalUnread: number,
}

export const NOTIFICATION_LIST_STATE_DEFAULT: NotificationListStateModel = {
  lastPage: new RestPage<Notification>(),
  items: [],
  loading: LoadingState.IDLE,
  pagination: new PaginationLazy(),
  params: {},
  totalUnread: 0
}

@StateRepository()
@State<NotificationListStateModel>({
  name: 'NotificationList',
  defaults: NOTIFICATION_LIST_STATE_DEFAULT
})
@Injectable()
export class NotificationListState extends NgxsDataRepository<NotificationListStateModel>{

  constructor(private dataService: NotificationHttpService) {
    super();
  }

  @Selector()
  static items(state: NotificationListStateModel) {
    return state.items
  }

  @Selector()
  static selected(state: NotificationListStateModel) {
    return state.selected
  }

  @Selector()
  static totalUnread(state: NotificationListStateModel) {
    return state.totalUnread
  }

  @Selector()
  static isLoading(state: NotificationListStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @Selector()
  static isLoadingLazy(state: NotificationListStateModel) {
    return state.loading === LoadingState.LOADING_LAZY;
  }

  @DataAction({ cancelUncompleted: true })
  get(params: GetAllNotificationsParams, pagination: PaginationLazy) {
    this.ctx.patchState({params, loading: LoadingState.LOADING})
    return this.dataService.getAll(params, pagination).pipe(
      finalize(() => { this.patchState({loading: LoadingState.IDLE})}),
      tap((page: RestPage<Notification>) => this.patchState({
        lastPage: page,
        items: page.content,
        pagination,
        totalUnread: !params.showAll ? page.totalElements : this.ctx.getState().totalUnread
      }))
    )
  }

  @DataAction({ cancelUncompleted: true })
  loadMore() {
    const params = this.ctx.getState().params
    const pagination = PaginationLazy.nextPart(this.ctx.getState().pagination)
    this.ctx.patchState({params, loading: LoadingState.LOADING_LAZY})
    return this.dataService.getAll(params, pagination).pipe(
      finalize(() => { this.patchState({loading: LoadingState.IDLE})}),
      tap((page: RestPage<Notification>) => this.patchState({lastPage: page, items: [...this.ctx.getState().items, ...page.content], pagination}))
    )
  }

  @DataAction()
  markAsRead(ids: number[]) {
    this.ctx.setState(patch({
      loading: LoadingState.LOADING
    }))
    return this.dataService.markAsRead(ids).pipe(
      switchMap(() => {
       return this.dataService.getAll(this.getState().params, this.getState().pagination)
      }),
      tap((page: RestPage<Notification>) => this.patchState({
        lastPage: page,
        items: page.content,
        totalUnread: !this.getState().params.showAll ? page.totalElements : this.ctx.getState().totalUnread - ids.length
      })),
    finalize(() => { this.patchState({loading: LoadingState.IDLE}) }),
    )
  }

  @DataAction()
  setSelected(selected: Notification) {
    this.ctx.patchState({ selected })
  }

  @DataAction()
  addReceived(notification: Notification) {
    this.ctx.setState(patch({
      items: insertItem(notification, 0),
      totalUnread: this.getState().totalUnread + 1,
      params: patch({ offset: (this.getState().params.offset || 0) + 1 })
    }))
  }

  @DataAction()
  resetItems() {
    this.ctx.setState({ ...NOTIFICATION_LIST_STATE_DEFAULT, ...{ totalUnread: this.getState().totalUnread } })
  }
}
