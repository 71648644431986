import { Directive, ElementRef, Input, NgModule, Renderer2 } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormGroupDirective } from "@angular/forms";

@Directive({
  selector: '[formGroup][touchAllOnSubmit], [formGroupName][touchAllOnSubmit]'
})
export class TouchAllOnSubmitDirective {

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private formGroupDirective: FormGroupDirective) {
    formGroupDirective.ngSubmit.subscribe({
      next: () => formGroupDirective.form.markAllAsTouched()
    })
  }
}

@NgModule({
  declarations: [TouchAllOnSubmitDirective],
  imports: [
    CommonModule
  ],
  exports: [TouchAllOnSubmitDirective]
})
export class TouchAllOnSubmitDirectiveModule {
}
