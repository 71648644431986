import { Injectable } from '@angular/core';
import { RxStompState } from '@stomp/rx-stomp';
import { Message } from '@stomp/stompjs';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomStompService } from "@services/custom-stomp.service";
import { NotificationListState } from "@store/notification-list.state";
import { AuthService } from "@services/auth.service";
import { Notification } from "@api/models/Notification";


@Injectable({ providedIn: `root` })
export class NotificationService {
  private readonly NOTIFICATION_SOUND_PATH = 'assets/sounds/notification.mp3';
  public notificationsSubscription$!: Subscription;

  constructor(
    private stompService: CustomStompService,
    private notificationListState: NotificationListState,
    private authService: AuthService,
  ) {}

  public subscribeOnNotifications(): void {
    combineLatest([
      this.authService.isAuthenticated$,
      this.stompService.connectionState$,
    ])
      .pipe(
        filter(([isAuthenticated, connectionState]) => {
          return connectionState === RxStompState.OPEN && isAuthenticated;
        }),
      )
      .subscribe(results => {
        this.notificationsSubscription$?.unsubscribe();
        this.notificationsSubscription$ = this.stompService
          .watch(`/queue/users/${this.authService.getUserEmail()}/notifications`)
          .subscribe(this.onNotification);
      });
  }

  private onNotification = (message: Message): void => {
    const notification = JSON.parse(message.body) as Notification;
    this.notificationListState.addReceived(notification);
    this.playSound();
  };

  public playSound(): void {
    const audio = new Audio(this.NOTIFICATION_SOUND_PATH);
    audio.volume = 0.7;
    audio.play();
  }
}
