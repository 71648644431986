export class Address {
  country?: string = '';
  state?: string = '';
  city?: string = '';
  street?: string = '';
  postalCode?: string = '';
  location?: GeoLocation;
}

export class GeoLocation {
  lat: string = '';
  lng: string = '';
}
