import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'enumToObjects' })
export class EnumToObjectsPipe implements PipeTransform {
  transform(enumeration: any, keyName: string = 'value', valueName: string = 'label'): any {
    let array = [];
    for (let item in enumeration) {
      let obj: {[key: string]: string} = {};
      obj[keyName] = item;
      obj[valueName] = enumeration[item];
      if (typeof enumeration[item] !== 'function') {
        array.push(obj);
      }
    }
    return array;
  }
}

@NgModule({
  declarations: [EnumToObjectsPipe],
  exports: [EnumToObjectsPipe],
})
export class EnumToObjectsPipeModule {
  static forRoot(): ModuleWithProviders<EnumToObjectsPipeModule> {
    return {
      ngModule: EnumToObjectsPipeModule,
      providers: [EnumToObjectsPipe]
    };
  }
}
