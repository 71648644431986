import { ComponentRef, Directive, ElementRef, Input, NgModule, OnChanges, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ProgressSpinner } from "primeng/progressspinner";

@Directive({
  selector: '[spinnerOver]'
})
export class SpinnerOverDirective implements OnChanges {
  private _spinnerOver?: boolean;
  @Input() set spinnerOver(value: boolean | undefined | null) {
    this._spinnerOver = value || false;
  }

  private _spinnerOverMarginTopPx?: number;
  @Input() set spinnerOverMarginTopPx(value: number | undefined) {
    this._spinnerOverMarginTopPx = value;
  }
  private spinnerRef: ComponentRef<ProgressSpinner> | undefined
  private nativeElement = this.elementRef.nativeElement;

  constructor(private elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update(this._spinnerOver as boolean)
  }

  update(value: boolean) {
    if (value) {
      this.renderer.setStyle(this.nativeElement, 'position', 'relative')
      this.addSpinner();
    } else {
      if (this.spinnerRef) {
        this.renderer.removeStyle(this.nativeElement, 'position')
        this.removeSpinner();
      }
    }
  }

  addSpinner() {
    for (let item of this.nativeElement.children) {
      this.renderer.setStyle(item, 'visibility', 'hidden')
    }
    this.spinnerRef = this.viewContainerRef.createComponent(ProgressSpinner)
    this.renderer.appendChild(this.nativeElement, this.spinnerRef.location.nativeElement)
    this.renderer.setStyle(this.spinnerRef.location.nativeElement, 'position', 'absolute')
    this.renderer.setStyle(this.spinnerRef.location.nativeElement, 'top', this._spinnerOverMarginTopPx ? `${this._spinnerOverMarginTopPx}px` : '50%')
    this.renderer.setStyle(this.spinnerRef.location.nativeElement, 'left', '50%')
    this.renderer.setStyle(this.spinnerRef.location.nativeElement, 'transform', `translate(-50%, ${this._spinnerOverMarginTopPx ? 0 : '-50%'})`)
  }

  removeSpinner() {
    for (let item of this.nativeElement.children) {
      this.renderer.removeStyle(item, 'visibility')
    }
    this.renderer.removeChild(this.nativeElement, this.spinnerRef?.location.nativeElement)
    this.spinnerRef = undefined;
  }
}

@NgModule({
  declarations: [SpinnerOverDirective],
  imports: [
    CommonModule
  ],
  exports: [SpinnerOverDirective]
})
export class SpinnerOverDirectiveModule { }
