import { User } from "@api/models/User";
import { LoadingState } from "@store/common/LoadingState";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { Injectable } from "@angular/core";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Selector, State } from "@ngxs/store";
import { InsuranceHttpService } from "@api/servies/insurance-http.service";
import { PartnerUserStateModel } from "@store/partner-user.state";
import { finalize, MonoTypeOperatorFunction, of, switchMap, tap } from "rxjs";
import { Utils } from "@utils/Utils";

export interface InsuranceUserStateModel {
  item: User,
  parentId?: number,
  loading: LoadingState,
  newAvatar?: string
}

export const INSURANCE_USER_STATE_DEFAULT: InsuranceUserStateModel = {
  item: new User(),
  loading: LoadingState.IDLE
}

@StateRepository()
@State<InsuranceUserStateModel>({
  name: 'InsuranceUser',
  defaults: INSURANCE_USER_STATE_DEFAULT
})
@Injectable()
export class InsuranceUserState extends NgxsDataRepository<InsuranceUserStateModel> {
  constructor(private dataService: InsuranceHttpService) {
    super();
  }

  @Selector()
  static item(state: InsuranceUserStateModel) {
    return state.item
  }

  @Selector()
  static isLoading(state: PartnerUserStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @Selector()
  static isSaving(state: PartnerUserStateModel) {
    return state.loading === LoadingState.SAVING;
  }

  @Selector()
  static avatar(state: PartnerUserStateModel) {
    return state.newAvatar || state.item.imageUrl;
  }

  @DataAction({cancelUncompleted: true})
  get(id: number, insuranceId: number) {
    this.ctx.patchState({...this.initialState, loading: LoadingState.LOADING, parentId: insuranceId})
    return this.dataService.getUser(id, insuranceId).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  create(item: User, insuranceId: number) {
    this.ctx.patchState({loading: LoadingState.SAVING, parentId: insuranceId})
    return this.dataService.createUser(item, insuranceId).pipe(
      this.loadingIdleFinalize,
      this.updateAvatarSwitchMap,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  update(item: User, insuranceId: number) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.updateUser(item, insuranceId).pipe(
      this.loadingIdleFinalize,
      this.updateAvatarSwitchMap,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  changeAvatar(avatar: string) {
    this.ctx.patchState({ newAvatar: avatar })
  }

  @DataAction()
  uploadAvatar(avatar: string) {
    this.ctx.patchState({newAvatar: avatar})
    const state = this.ctx.getState()
    const formData = new FormData()
    formData.append('file', Utils.dataURItoBlob(avatar))
    return this.dataService.updateUserImage(state.item.id as number, state.parentId as number, formData).pipe(this.requestSuccessTap)
  }

  private requestSuccessTap: MonoTypeOperatorFunction<User> = tap((item: User) => this.patchState({item, newAvatar: undefined}))

  private loadingIdleFinalize: MonoTypeOperatorFunction<User> = finalize(() => { this.patchState({loading: LoadingState.IDLE})})

  private updateAvatarSwitchMap: MonoTypeOperatorFunction<User> = switchMap((item: User) => {
    const state = this.ctx.getState()
    if (state.newAvatar) {
      const formData = new FormData()
      formData.append('file', Utils.dataURItoBlob(state.newAvatar))
      return this.dataService.updateUserImage(item.id as number, state.parentId as number, formData)
    } else {
      return of(item)
    }
  })
}
