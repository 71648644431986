import { Component, NgModule, OnInit } from '@angular/core';
import { SharedModule } from "@shared/shared.module";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { UntilDestroy } from "@ngneat/until-destroy";
import { StudyState } from "@store";
import { StudyType } from '@api/models/enums/StudyType';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Study } from "@api/models/Study";
import { SnackBarService } from "@services/snak-bar.service";
import { Router } from "@angular/router";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-study-type-selector-dialog',
  templateUrl: './study-type-selector-dialog.component.html',
  styleUrls: ['./study-type-selector-dialog.component.scss']
})
export class StudyTypeSelectorDialogComponent implements OnInit {

  StudyType = StudyType;
  formGroup: FormGroup;

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              public studyState: StudyState,
              private formBuilder: FormBuilder,
              private snackBarService: SnackBarService,
              private router: Router) {
    this.formGroup = this.formBuilder.group({
      type: [StudyType.DRUG, Validators.required]
    })
  }

  ngOnInit(): void {
  }

  createStudy() {
    this.studyState.reset();
    let study: Study = new Study()
    study.type = this.formGroup.controls.type.value;
    this.studyState.setType(this.formGroup.controls.type.value)
    this.ref.close()
    this.router.navigate(['studies/item'])
  }
}

@NgModule({
  declarations: [
    StudyTypeSelectorDialogComponent
  ],
  imports: [
    SharedModule.forRoot(),
  ]
})
export class RuleViewDialogModule { }
