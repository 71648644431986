import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from "@angular/core";
import { Address } from "@api/models/Address";
import { Country, COUNTRY_LIST } from "@api/models/Country";

@Pipe({name: 'addressConcat'})
export class AddressConcatPipe implements PipeTransform {
  transform(address?: Address): string {
    if (!address) return '';
    if (!(address.street && address.postalCode && address.city && address.state) && address.country) {
      return `${COUNTRY_LIST.find((item: Country) => item.code === address.country)?.name || ''}`
    }
    return (
      (address.street || '')
      + (address.postalCode ? `, ${address.postalCode}` : '')
      + (address.city ? `, ${address.city}` : '')
      + (address.state ? `, ${address.state}` : '')
      + (address.country ? `, ${COUNTRY_LIST.find((item: Country) => item.code === address.country)?.name || ''}` : '')
    );
  }
}

@NgModule({
  declarations: [AddressConcatPipe],
  exports: [AddressConcatPipe],
})
export class AddressConcatPipeModule {
  static forRoot(): ModuleWithProviders<AddressConcatPipeModule> {
    return {
      ngModule: AddressConcatPipeModule,
      providers: [AddressConcatPipe]
    };
  }
}
