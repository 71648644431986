export enum StudyCountryStateType {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  PREPARED = 'PREPARED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export namespace StudyCountryStateType {
  export function isClosed(state: StudyCountryStateType) {
    return [StudyCountryStateType.CANCELED, StudyCountryStateType.COMPLETED].includes(state);
  }

  export function isBeforeSigned(state: StudyCountryStateType) {
    return [StudyCountryStateType.DRAFT, StudyCountryStateType.PENDING].includes(state);
  }

  export function isSignedOrPrepared(state: StudyCountryStateType) {
    return [StudyCountryStateType.SIGNED, StudyCountryStateType.PREPARED].includes(state);
  }

  export function isStartedOrCompleted(state: StudyCountryStateType) {
    return [StudyCountryStateType.STARTED, StudyCountryStateType.COMPLETED].includes(state);
  }

  export function isOrWasSigned(state: StudyCountryStateType) {
    return [StudyCountryStateType.SIGNED, StudyCountryStateType.PREPARED, StudyCountryStateType.STARTED, StudyCountryStateType.COMPLETED].includes(state);
  }

  export function isOrWasPrepared(state: StudyCountryStateType) {
    return [StudyCountryStateType.PREPARED, StudyCountryStateType.STARTED, StudyCountryStateType.COMPLETED].includes(state);
  }
}
