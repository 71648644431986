import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toFormControlName' })
export class ToFormControlNamePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return '';
    return value.toLowerCase()
      // replace all special characters by spaces
      .replace(/[^a-zA-Z ]/g, ' ')
      // remove all spaces
      .replace(/\s|\s+/g, '');
  }
}

@NgModule({
  declarations: [ToFormControlNamePipe],
  exports: [ToFormControlNamePipe],
})
export class ToFormControlNamePipeModule {
  static forRoot(): ModuleWithProviders<ToFormControlNamePipeModule> {
    return {
      ngModule: ToFormControlNamePipeModule,
      providers: [ToFormControlNamePipe]
    };
  }
}
