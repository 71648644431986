import { map, Observable, of, } from 'rxjs';
import { Directive, Injectable, Input, ModuleWithProviders, NgModule } from "@angular/core";
import { AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { PartnerHttpService } from "@api/servies/partner-http.service";
import { StaticInjector } from "@utils/Injector";


@Injectable()
@Directive({
  selector: '[partnerInitialUnique]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: PartnerInitialUniqueValidator, multi: true}]
})
export class PartnerInitialUniqueValidator implements AsyncValidator {
  private static readonly ERROR_KEY = 'unique'
  private static readonly ERROR = {[PartnerInitialUniqueValidator.ERROR_KEY]: true}
  @Input() partnerInitialUnique?: string

  constructor(private partnerHttpService: PartnerHttpService) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return PartnerInitialUniqueValidator.check(control, this.partnerInitialUnique, this.partnerHttpService)
  }

  static fn(initialValue?: string): AsyncValidatorFn {
    return (control: AbstractControl) => this.check(control, initialValue, StaticInjector.instance?.get(PartnerHttpService));
  }

  private static check(control: AbstractControl, initialValue?: string, httpService?: PartnerHttpService): Observable<ValidationErrors | null> {
    if (!control.value || control.value === initialValue) return of(null);
    if (!httpService) return of(PartnerInitialUniqueValidator.ERROR)
    return httpService.getAll({initial: control.value})
      .pipe(map(page => page.totalElements !== 0 ? PartnerInitialUniqueValidator.ERROR : null))
  }
}

@NgModule({
  declarations: [PartnerInitialUniqueValidator],
  exports: [PartnerInitialUniqueValidator],
})
export class PartnerInitialUniqueValidatorModule {
  static forRoot(): ModuleWithProviders<PartnerInitialUniqueValidatorModule> {
    return {
      ngModule: PartnerInitialUniqueValidatorModule,
      providers: [PartnerInitialUniqueValidator]
    };
  }
}
