import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { map, Observable } from "rxjs";
import { Role } from "@models/enums/Role";

@Injectable({providedIn: 'root'})
export class UserGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess();
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess();
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess();
  }

  hasAccess(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.isLoggedIn().pipe(map(isLoggedIn => {
      if (isLoggedIn && this.authService.hasAnyRole(Role.PARTNER, Role.INSURER)) {
        return true;
      } else {
        this.router.navigate(['/'])
        return false;
      }
    }))
  }
}
