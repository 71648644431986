import { HttpErrorResponse } from "@angular/common/http";
import { httpStatusCodeResponses } from "./Messages";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";

export class Utils {

  static copy(source: any) {
    return Object.assign({}, source)
  }

  static clone(source: any) {
    return JSON.parse(JSON.stringify(source))
  }

  static arrayToHttpParam(array: Array<any>) {
    return array.join(',')
  }

  /**
   Works only for new angular httpClient
   */
  static getErrorMessage(err: HttpErrorResponse | any): string {
    let message
    try {
      let jsonResponse = JSON.parse(err.error);
      message = jsonResponse.message;

      if (jsonResponse.errors) {
        message += jsonResponse.errors.reduce((accumulator: any, currentValue: any) => accumulator + " " + currentValue.defaultMessage + ".", ". ");
      }
      return message
    } catch (e) {
      if (err.error != null && typeof err.error == 'object') {
        message = err.error.message ? err.error.message : httpStatusCodeResponses[err.status];
      } else {
        message = err.error ? err.error : httpStatusCodeResponses[err.status];
      }
      return message ? message : 'Unknown error'
    }
  }

  static capitalize(string: string): string {
    if (!string) return "";
    let [s, ...tring] = (string.toLowerCase() as any);
    return [s.toUpperCase(), ...tring].join('');
  }

  static enumToArrayList(enumeration: any): Array<string> {
    return Object.values(enumeration)
      .filter(item => typeof item != 'function')
      .map(item => (item as any).toString());
  }

  static dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
      // byteString = decodeURI(dataURI.split(',')[1]);
    }

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  static enumCaseToText(value?: string, skip?: string): string {
    let skipArray = skip?.split(',')
    if (!value) return '';
    let result = value;
    result = result.replace(/_/g, ' ')
    if (!skipArray?.includes(value)) {
      result = result.toLowerCase();
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  }

  static updateTreeValidity(group: FormGroup | FormArray, emitEvent = false): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl: AbstractControl = (group.controls as any)[key] as AbstractControl;
      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        Utils.updateTreeValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity({emitEvent: emitEvent});
      }
    });
  }

  static uniqueByKey<T>(array: Array<T>, key: string): Array<T> {
    const result: Array<T> = [];
    array.forEach((x: any) => {
      const found = result.find((y: any) => y[key] === x[key]);
      if (!found) {
        result.push(x);
      }
    });
    return result;
  }

  static parseCookieLikeString(header?: string | null): {[key: string]: string} {
    if (!header) return {};
    let pairs = header.split(";");
    return pairs.map(pair => pair.trim().split("=")).reduce((reduced, pair) => {
      reduced[decodeURIComponent(pair[0]?.trim())] = decodeURIComponent(pair[1]?.trim().replaceAll('"', ''));
      return reduced;
    }, {} as {[key: string]: string})
  }
}


