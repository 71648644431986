import { PartnerType } from "@api/models/enums/PartnerType";
import { Address } from "@api/models/Address";
import { User } from "@api/models/User";
import { MembershipTier } from "@api/models/enums/MembershipTier";
import { Account } from "@api/models/Account";

export class Partner {
  id?: number;
  name: string = '';
  initial: string = '';
  note: string = '';
  creationDate!: string;
  modificationDate!: string;
  type?: PartnerType;
  address: Address = new Address();
  account: Account = new Account();
  imageUrl?: string;
  users: User[] = [];
  tier: MembershipTier = MembershipTier.BASIC;
}

