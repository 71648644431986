import { Partner } from "@api/models/Partner";
import { Selector, State } from "@ngxs/store";
import { PartnerHttpService } from "@api/servies/partner-http.service";
import { LoadingState } from "@store/common/LoadingState";
import { EMPTY, finalize, map, MonoTypeOperatorFunction, Observable, of, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Profile } from "@api/models/Profile";
import { InsuranceHttpService } from "@api/servies/insurance-http.service";
import { Insurance } from "@api/models/Insurance";
import { Role } from "@models/enums/Role";
import { User } from "@api/models/User";
import { TokenProfileParts } from "@models/UserProfile";
import { Utils } from "@utils/Utils";
import { patch } from "@ngxs/store/operators";


export interface ProfileStateModel {
  item: Profile;
  tokenParts: TokenProfileParts
  loading: LoadingState;
  newAvatar?: string | null;
}

export const PROFILE_STATE_DEFAULT: ProfileStateModel = {
  item: new Profile(),
  tokenParts: {
    name: ''
  },
  loading: LoadingState.IDLE
}

@StateRepository()
@State<ProfileStateModel>({
  name: 'Profile',
  defaults: PROFILE_STATE_DEFAULT
})
@Injectable()
export class ProfileState extends NgxsDataRepository<ProfileStateModel> {

  constructor(private partnerHttpService: PartnerHttpService,
              private insuranceHttpService: InsuranceHttpService) {
    super();
  }

  @Selector()
  static item(state: ProfileStateModel) {
    return state.item
  }

  @Selector()
  static imageUrl(state: ProfileStateModel) {
    return state.newAvatar || state.tokenParts.imageUrl || state.item.imageUrl
  }

  @Selector()
  static fullName(state: ProfileStateModel) {
    return state.item.firstName && state.item.lastName ? `${state.item.firstName} ${state.item.lastName}` : state.tokenParts.name
  }

  @Selector()
  static isLoading(state: ProfileStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @Selector()
  static isSaving(state: ProfileStateModel) {
    return state.loading === LoadingState.SAVING;
  }

  @Selector()
  static tier(state: ProfileStateModel) {
    if (state.item.partner) {
      return state.item.partner.tier
    } else if (state.item.insurance) {
      return state.item.insurance.tier
    } else {
      return null;
    }
  }

  @DataAction({cancelUncompleted: true})
  getMy(userName: string, role?: Role) {
    this.ctx.patchState({...this.initialState, tokenParts: this.snapshot.tokenParts , loading: LoadingState.LOADING})
    let request: Observable<any>;
    if (role === Role.PARTNER) {
      request = this.partnerHttpService.getMy().pipe(
        map((item: Partner) => {
          let profile: Profile = {...(item.users.find(user => user.userName === userName) as User)}
          profile.partner = {...item}
          delete (profile as any).users
          this.patchState({item: profile})
          return profile
        })
      )
    } else if (role === Role.INSURER) {
      request = this.insuranceHttpService.getMy().pipe(
        map((item: Insurance) => {
          let profile: Profile = {...(item.users.find(user => user.userName === userName) as User)}
          profile.insurance = {...item}
          delete (profile as any).users
          this.patchState({item: profile})
          return profile
        })
      )
    } else {
      return EMPTY
    }
    return request.pipe(this.loadingIdleFinalize)
  }

  @DataAction()
  update(item: Profile, role: Role) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    const profileToUpdate = {...this.snapshot.item, ...item};
    let request: Observable<any>;
    if (role === Role.INSURER && profileToUpdate.insurance?.id) {
      request = this.insuranceHttpService.updateOwn(profileToUpdate as User, profileToUpdate.insurance.id)
        .pipe(map((data: User) => {
            let profile: Profile = {...data}
            return profile
          }
        ))
    } else if (role === Role.PARTNER && profileToUpdate.partner?.id) {
      request = this.partnerHttpService.updateOwn(profileToUpdate as User, profileToUpdate.partner.id)
        .pipe(map((data: User) => {
            let profile: Profile = {...data}
            return profile
          }
        ))
    } else {
      return EMPTY;
    }
    return request.pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap
    )
  }

  @DataAction()
  setTokenParts(tokenParts: TokenProfileParts) {
    this.ctx.patchState({tokenParts})
  }

  @DataAction()
  changeAvatar(avatar?: string | null) {
    this.ctx.patchState({newAvatar: avatar})
  }

  @DataAction()
  uploadAvatar(avatar: string) {
    this.ctx.patchState({newAvatar: avatar})
    const item = this.ctx.getState().item
    const formData = new FormData()
    formData.append('file', Utils.dataURItoBlob(avatar))
    if (item.partner?.id) {
      return this.partnerHttpService.updateUserImage(item.id as number, item.partner.id as number, formData).pipe(this.requestSuccessTap)
    }
    if (item.insurance?.id) {
      return this.insuranceHttpService.updateUserImage(item.id as number, item.insurance.id as number, formData).pipe(this.requestSuccessTap)
    }
    return of(item)
  }

  private loadingIdleFinalize: MonoTypeOperatorFunction<Profile> = finalize(() => {
    this.patchState({loading: LoadingState.IDLE})
  })

  private requestSuccessTap: MonoTypeOperatorFunction<Profile> = tap((profile: Profile) => {
    let updatedProfile = {...profile}
    if (this.getState().item.partner) {
      profile.partner = {...this.getState().item.partner} as Partner
      profile.partner.users = [...profile.partner.users]
      let index = profile.partner?.users.findIndex(user => user.id === profile.id);
      profile.partner.users[index] = updatedProfile as User
    }
    if (this.getState().item.insurance) {
      profile.insurance = {...this.getState().item.insurance} as Insurance
      profile.insurance.users = [...profile.insurance.users]
      let index = profile.insurance?.users.findIndex(user => user.id === profile.id);
      profile.insurance.users[index] = updatedProfile as User
    }
    this.setState(patch<ProfileStateModel>({
      item: profile,
      newAvatar: undefined,
      tokenParts: this.snapshot.tokenParts
    }))
  })
}
