import { Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-layout-main',
  templateUrl: 'layout-main.component.html',
  styleUrls: ['layout-main.component.scss']
})
export class LayoutMainComponent {

  constructor() {
  }

}
