import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { routing } from "./app.routing";
import { LayoutModule } from "./layout/layout.module";
import { CommonModule, registerLocaleData } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { KeycloakBearerInterceptor, KeycloakService } from "keycloak-angular";
import { keycloakInitializer } from "@utils/KeycloakInitializer";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { environment } from "@env/environment";
import { AppStates } from "@store";
import { NGXS_DATA_STORAGE_PLUGIN } from "@angular-ru/ngxs/storage";
import { NGXS_DATA_CONFIG, NgxsDataPluginModule } from "@angular-ru/ngxs";
import { TableModule } from "primeng/table";
import { SharedModule } from "@shared/shared.module";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { AccessDeniedInterceptor } from "@interceptors/access-denied.interceptor";
import { NgcCookieConsentConfig, NgcCookieConsentModule } from "ngx-cookieconsent";

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain,
  },
  palette: {
    popup: {
      background: '#484f57',
      text: '#fdfdfe',
    },
    button: {
      background: '#3b81f5',
    },
  },
  theme: 'classic',
  type: 'info',
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    routing,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    LayoutModule,
    TableModule,
    SharedModule,
    NgxsModule.forRoot(AppStates, {
      developmentMode: !environment.production,
    }),
    NgxsDataPluginModule.forRoot([NGXS_DATA_STORAGE_PLUGIN]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: true,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    KeycloakService,
    {provide: Window, useValue: window},
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessDeniedInterceptor,
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: NGXS_DATA_CONFIG, useValue: {dataActionSubscribeRequired: false}},
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
