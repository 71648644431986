import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filesize', pure: true })
export class FileSizePipe implements PipeTransform {
  transform(value: any): string {
    if (value === null || value === undefined || value === '') return '0 B';
    return this.humanFileSize(value);
  }

  private humanFileSize(size: number): string {
    if (size === 0) return '0 b';
    const i: number = Math.floor(Math.log(size) / Math.log(1024));
    // eslint-disable-next-line no-restricted-properties
    return `${(size / Math.pow(1024, i)).toFixed(2)} ${
      ['b', 'kb', 'mb', 'gb', 'tb'][i]
    }`;
  }
}

@NgModule({
  declarations: [FileSizePipe],
  exports: [FileSizePipe],
})
export class FileSizePipeModule {
  static forRoot(): ModuleWithProviders<FileSizePipeModule> {
    return {
      ngModule: FileSizePipeModule,
      providers: [FileSizePipe]
    };
  }
}
