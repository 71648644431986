import { Partner } from "@api/models/Partner";
import { Insurance } from "@api/models/Insurance";
import { Salutation } from "@api/models/enums/Salutation";

export class Profile {
  id?: number;
  firstName!: string;
  lastName!: string;
  positionTitle?: string;
  salutation!: Salutation;
  phone!: string;
  email!: string;
  userName!: string;
  imageUrl?: string;
  // Partner or Insurance existence dependent on type of company
  partner?: Partner
  insurance?: Insurance
}
