<div class="header">
  <h2>Notifications</h2>
  <div class="right-block">
    <label for="show_all" class="noselect">Only show unread</label>
    <p-inputSwitch [(ngModel)]="showAll" inputId="show_all" (onChange)="onShowAllChange($event)"></p-inputSwitch>
  </div>
</div>

<div class="content">
  <ng-container *ngIf="notifications$ | async as notifications">
    <div class="top-line" *ngIf="notifications.length && (isLoading$ | async) !== true">
      <div>Latest</div>
      <div class="read-all noselect" (click)="markAllAsRead()">Mark all as read</div>
    </div>

    <div class="notifications-list" (scroll)="onNotificationsScroll($event)">
      <ng-template ngFor let-notification [ngForOf]="notifications" let-i="index">
        <div class="notification" (click)="notificationClick(notification)">
          <div class="notification-icon-wrapper">
            <p-avatar *ngIf="notification.imageUrl" [icon]="$any(notification.imageUrl ? undefined : 'fa-solid fa-user-tie')" styleClass="notification-avatar"
                      shape="circle" [image]="$any(notification.imageUrl)"></p-avatar>
            <i class="pi pi-envelope" *ngIf="!notification.imageUrl"></i>
          </div>
          <div class="notification-body">
            <div class="notification-title">{{notification.title}}</div>
            <div class="notification-content">{{notification.content}}</div>
            <div class="notification-date">{{notification.creationDate | timeago}}</div>
          </div>
          <div class="notification-status">
            <div class="status-icon" *ngIf="!notification.read"></div>
          </div>
        </div>
      </ng-template>

      <div class="spinner-wrapper" *ngIf="(isLoading$ | async) === true || (isLoadingLazy$ | async) === true">
        <p-progressSpinner styleClass="spinner" strokeWidth="4"></p-progressSpinner>
      </div>
    </div>

    <div class="empty" *ngIf="!notifications.length && (isLoading$ | async) !== true">You have no any notifications yet</div>
  </ng-container>
</div>
