import { ContractStateType } from "@api/models/enums/ContractStateType";
import { Partner } from "@api/models/Partner";
import { User } from "@api/models/User";
import { Study } from "@api/models/Study";
import { Insurance } from "@api/models/Insurance";
import { PremiumItem } from "@api/models/PremiumItem";
import { DocumentWrapper } from "@api/models/DocumentWrapper";
import { AmendmentConfirmation } from "@api/models/AmendmentConfirmation";

export class Contract {
  id!: number;
  startDate!: string;
  endDate!: string;
  state!: ContractStateType;
  item!: PremiumItem
  sponsor!: Partner;
  partner!: Partner;
  insurance!: Insurance;
  insuranceContact!: User;
  partnerContact!: User;
  study!: Study;
  studyId!: string;
  studyTitle!: string;
  invoicePaid!: boolean;
  policy?: DocumentWrapper;
  policyNumber?: string;
  amendmentConfirmations: AmendmentConfirmation[] = []
}
