import { Study } from "@api/models/Study";
import { Insurance } from "@api/models/Insurance";
import { PremiumItem } from "@api/models/PremiumItem";

export class Offer {
  id!: number;
  items: PremiumItem[] = [];
  study?: Study;
  insurance?: Insurance;
}
