import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { API_URL } from "@utils/Constants";
import { CustomHttpParameterCodec } from "@utils/CustomHttpParameterCodec";
import { Pagination } from "@api/models/Pagination";
import { RestPage } from "@api/models/RestPage";
import { Observable } from "rxjs";
import { Contract } from "@api/models/Contract";
import { ContractPatchRequest } from "@api/models/ContractPatchRequest";
import { Study } from "@api/models/Study";
import { ContractCreate } from "@api/models/ContractCreate";
import { AmendmentConfirmation } from "@api/models/AmendmentConfirmation";
import { AuditTrail } from "@api/models/AuditTrail";


@Injectable({ providedIn: 'root' })
export class ContractHttpService {
  private readonly URL = `${API_URL}/contracts`;

  constructor(private http: HttpClient) { }

  getAll(params: any, pagination: Pagination = new Pagination()): Observable<RestPage<Contract>> {
    params = new HttpParams({ fromObject: Object.assign(params, pagination), encoder: new CustomHttpParameterCodec });
    return this.http.get<RestPage<Contract>>(`${this.URL}`, { params });
  }

  getById(id: number): Observable<Contract> {
    return this.http.get<Contract>(`${this.URL}/${id}`);
  }

  create(contractCreate: ContractCreate): Observable<Contract> {
    return this.http.post<Contract>(`${this.URL}`, contractCreate);
  }

  update(contract: Contract): Observable<Contract> {
    return this.http.put<Contract>(`${this.URL}/${contract.id}`, contract);
  }

  patch(patch: ContractPatchRequest): Observable<Contract> {
    return this.http.patch<Contract>(`${this.URL}/${patch.id}`, patch);
  }

  uploadPolicy(id: number, formData: FormData): Observable<Contract> {
    return this.http.put<Contract>(`${this.URL}/${id}/policy`, formData);
  }

  removePolicy(id: number) {
    return this.http.delete<Contract>(`${this.URL}/${id}/policy`);
  }

  downloadDocuments(id: number): Observable<any> {
    const httpOptions = {responseType: 'blob' as 'json', observe: 'response' as any};
    return this.http.get<HttpResponse<Blob>>(`${this.URL}/${id}/documents`, httpOptions)
  }

  downloadInvoice(id: number): Observable<any> {
    const httpOptions = {responseType: 'blob' as 'json', observe: 'response' as any};
    return this.http.get<HttpResponse<Blob>>(`${this.URL}/${id}/invoice`, httpOptions)
  }

  patchPaymentState(contractId: number, contract: Contract) {
    return this.http.patch<Study>(`${this.URL}/${contractId}/payment`, contract);
  }

  confirmAmendment(id: number, sequence: number, amendmentConfirmation: AmendmentConfirmation): Observable<Study> {
    return this.http.patch<Study>(`${this.URL}/${id}/amendmentConfirmations/${sequence}`, amendmentConfirmation);
  }

  getAudit(contractId: number) {
    return this.http.get<AuditTrail[]>(`${this.URL}/${contractId}/audit`);
  }
}
