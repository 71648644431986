import { Selector, State } from "@ngxs/store";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, MonoTypeOperatorFunction, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Rule } from "@api/models/Rule";
import { RuleHttpService } from "@api/servies/rule-http.service";


export interface RuleStateModel {
  item: Rule;
  loading: LoadingState;
  existingRuleCountries: string[];
}

export const RULE_STATE_DEFAULT: RuleStateModel = {
  item: new Rule(),
  loading: LoadingState.IDLE,
  existingRuleCountries: [],
}

@StateRepository()
@State<RuleStateModel>({
  name: 'Rule',
  defaults: RULE_STATE_DEFAULT
})
@Injectable()
export class RuleState extends NgxsDataRepository<RuleStateModel>{

  constructor(private dataService: RuleHttpService) {
    super();
  }

  @Selector()
  static item(state: RuleStateModel) {
    return state.item
  }

  @Selector()
  static isLoading(state: RuleStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @Selector()
  static isSaving(state: RuleStateModel) {
    return state.loading === LoadingState.SAVING;
  }

  @Selector()
  static existingRuleCountries(state: RuleStateModel) {
    return state.existingRuleCountries
  }

  @DataAction({ cancelUncompleted: true })
  get(countryCode: string) {
    this.ctx.patchState({...this.initialState, loading: LoadingState.LOADING})
    return this.dataService.getById(countryCode).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  create(item: Rule) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.create(item).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  update(item: Rule) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.update(item).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction({ cancelUncompleted: true })
  getExistingRuleCountries() {
    return this.dataService.getExistingCountry().pipe(
      tap(existingRuleCountries => {
        this.patchState({existingRuleCountries})
      })
    )
  }

  private requestSuccessTap: MonoTypeOperatorFunction<Rule> = tap((item: Rule) => this.patchState({item}))

  private loadingIdleFinalize: MonoTypeOperatorFunction<Rule> = finalize(() => {this.patchState({loading: LoadingState.IDLE})})

}
