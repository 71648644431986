import { Directive, ElementRef, Input, NgModule, Renderer2 } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormGroupDirective } from "@angular/forms";

@Directive({
  selector: '[formControlName][dirtyOnSubmit]'
})
export class DirtyOnSubmitDirective {
  @Input() dirtyOnSubmit?: string;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private formGroupDirective: FormGroupDirective) {
    formGroupDirective.ngSubmit.subscribe({
      next: () => renderer.addClass(elementRef.nativeElement, this.dirtyOnSubmit || 'ng-dirty')
    })
  }
}

@NgModule({
  declarations: [DirtyOnSubmitDirective],
  imports: [
    CommonModule
  ],
  exports: [DirtyOnSubmitDirective]
})
export class DirtyOnSubmitDirectiveModule {
}
