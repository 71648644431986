import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enumCaseToText' })
export class EnumCaseToTextPipe implements PipeTransform {
  transform(value?: string, skip?: string): string {
    let skipArray = skip?.split(',')
    if (!value) return '';
    let result = value;
    result = result.replace(/_/g, ' ')
    if (!skipArray?.includes(value)) {
      result = result.toLowerCase();
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  }
}

@NgModule({
  declarations: [EnumCaseToTextPipe],
  exports: [EnumCaseToTextPipe],
})
export class EnumCaseToTextPipeModule {
  static forRoot(): ModuleWithProviders<EnumCaseToTextPipeModule> {
    return {
      ngModule: EnumCaseToTextPipeModule,
      providers: [EnumCaseToTextPipe]
    };
  }
}
