import { Directive, ElementRef, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from "@models/enums/Role";
import { AuthService } from "@services/auth.service";

@Directive({
  selector: '[hasRole],[hasAnyRole]'
})
export class HasRoleDirective {
  @Input() set hasRole(role: Role) {
    this.showIf(this.authService.hasRole(role))
  }
  @Input() set hasAnyRole(roles: Role[]) {
    this.showIf(this.authService.hasAnyRole(...roles))
  }

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {
  }

  private showIf(value: boolean) {
    if(value) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}

@NgModule({
  declarations: [HasRoleDirective],
  imports: [
  ],
  exports: [HasRoleDirective]
})
export class HasRoleDirectiveModule { }
