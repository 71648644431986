import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NotificationListState } from "@store";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { SnackBarService } from "@services/snak-bar.service";
import { Notification } from "@api/models/Notification";
import { PaginationLazy } from "@api/models/PaginationLazy";
import { ScrollPanel } from "primeng/scrollpanel";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Router } from "@angular/router";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-notifications-popup',
  templateUrl: './notifications-popup.component.html',
  styleUrls: ['./notifications-popup.component.scss']
})
export class NotificationsPopupComponent implements OnInit {
  @ViewChild('notificationsScrollPanel', { static: false })
  public notificationsScrollPanel!: ScrollPanel;

  @Output()
  onNotificationClick: EventEmitter<void> = new EventEmitter<void>()

  @Select(NotificationListState.items)
  public notifications$!: Observable<Notification[]>;
  @Select(NotificationListState.isLoading)
  public isLoading$!: Observable<boolean>;
  @Select(NotificationListState.isLoadingLazy)
  public isLoadingLazy$!: Observable<boolean>;

  showAll: boolean = true;

  constructor(public notificationListState: NotificationListState,
              private snackBarService: SnackBarService,
              private router: Router) {
  }

  ngOnInit(): void {

  }

  notificationClick(notification: Notification) {
    if (!notification.read) {
      this.notificationListState.markAsRead([notification.id]).subscribe({
        error: this.snackBarService.handleHttpError
      })
    }
    this.onNotificationClick.emit()
    this.router.navigateByUrl(notification.link as string)
  }

  onShowAllChange(event: any) {
    this.notificationListState.resetItems()
    this.notificationListState.get({ showAll: !event.checked }, new PaginationLazy())
      .subscribe({ error: this.snackBarService.handleHttpError })
  }

  markAllAsRead() {
    const ids = this.notificationListState.snapshot.items.filter(item => !item.read).map(item => item.id);
    if (ids.length) {
      this.notificationListState.markAsRead(ids).subscribe({
        error: this.snackBarService.handleHttpError
      })
    }
  }

  onNotificationsScroll(event: any) {
    const scrolledToEnd = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight
    if (scrolledToEnd && !this.notificationListState.snapshot.lastPage.last) {
      this.notificationListState.loadMore()
        .subscribe({ error: this.snackBarService.handleHttpError })
    }
  }
}
