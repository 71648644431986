import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogModule } from "primeng/dialog";
import { ToastModule } from "primeng/toast";
import { ConfirmationService, MessageService, TreeDragDropService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MenuModule } from "primeng/menu";
import { PanelMenuModule } from "primeng/panelmenu";
import { ButtonModule } from "primeng/button";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ContextMenuModule } from "primeng/contextmenu";
import { MessageModule } from "primeng/message";
import { CalendarModule } from "primeng/calendar";
import { SliderModule } from "primeng/slider";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { DropdownModule } from "primeng/dropdown";
import { ToggleButtonModule } from "primeng/togglebutton";
import { InputSwitchModule } from "primeng/inputswitch";
import { ListboxModule } from "primeng/listbox";
import { CheckboxModule } from "primeng/checkbox";
import { CardModule } from "primeng/card";
import { KeyFilterModule } from "primeng/keyfilter";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { MessagesModule } from "primeng/messages";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { EnumToObjectsPipeModule } from "@pipes/enum-to-objects.pipe";
import { CountryCodeToNamePipeModule } from "@pipes/country-code-to-name.pipe";
import { AutoCompleteModule } from "primeng/autocomplete";
import { NgxErrorsModule } from "@ngspot/ngx-errors";
import { DirtyOnSubmitDirectiveModule } from "@directives/dirty-on-submit.directive";
import { SpinnerOverDirectiveModule } from "@directives/spinner-over.directive";
import { AvatarModule } from "primeng/avatar";
import { RippleModule } from "primeng/ripple";
import { EnumCaseToTextPipeModule } from "@pipes/enum-case-to-text.pipe";
import { SelectButtonModule } from "primeng/selectbutton";
import { UuidPipeModule } from "@pipes/uuid.pipe";
import { HasRoleDirectiveModule } from "@directives/has-role.directive";
import { FileSizePipeModule } from "@pipes/file-size.pipe";
import { ToFormControlNamePipeModule } from "@pipes/to-form-control-name.pipe";
import { CurrencySymbolPipeModule } from "@pipes/currency-symbol.pipe";
import { PositiveNumericOnlyDirectiveModule } from "@directives/positive-numeric-only.directive";
import { IntegerOnlyDirectiveModule } from "@directives/integer-only.directive";
import { DecimalOnlyDirectiveModule } from "@directives/decimal-only.directive";
import { ToClassNamePipeModule } from "@pipes/to-class-name.pipe";
import { InputNumberModule } from "primeng/inputnumber";
import { YesNoPipeModule } from "@pipes/yes-no.pipe";
import { AddressConcatPipeModule } from '@pipes/address-concat.pipe';
import { UpperLettersOnlyDirectiveModule } from "@directives/upper-letters-only.directive";
import { PartnerInitialUniqueValidatorModule } from "@validators/partner-initial-unique.validator";
import { UserEmailUniqueValidatorModule } from "@validators/user-email-unique.validator";
import { TouchAllOnSubmitDirectiveModule } from "@directives/touch-all-on-submit.directive";
import { VarDirectiveModule } from "@directives/ng-var.directive";


const PROVIDERS = [
  MessageService,
  TreeDragDropService,
  DialogService,
  ConfirmationService
]

@NgModule({
  declarations: [],
  imports: [
    // Developed Modules
    EnumToObjectsPipeModule.forRoot(),
    CountryCodeToNamePipeModule.forRoot(),
    EnumCaseToTextPipeModule.forRoot(),
    UuidPipeModule.forRoot(),
    FileSizePipeModule.forRoot(),
    ToFormControlNamePipeModule.forRoot(),
    CurrencySymbolPipeModule.forRoot(),
    ToClassNamePipeModule.forRoot(),
    YesNoPipeModule.forRoot(),
    DirtyOnSubmitDirectiveModule,
    SpinnerOverDirectiveModule,
    HasRoleDirectiveModule,
    PositiveNumericOnlyDirectiveModule,
    IntegerOnlyDirectiveModule,
    DecimalOnlyDirectiveModule,
    UpperLettersOnlyDirectiveModule,
    TouchAllOnSubmitDirectiveModule,
    PartnerInitialUniqueValidatorModule.forRoot(),
    UserEmailUniqueValidatorModule.forRoot(),
    VarDirectiveModule,
    // PRIME NG
    DialogModule,
    ToastModule,
    ConfirmDialogModule,
    MenuModule,
    PanelMenuModule,
    ButtonModule,
    TieredMenuModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    ContextMenuModule,
    MessagesModule,
    MessageModule,
    CalendarModule,
    DynamicDialogModule,
    SliderModule,
    DropdownModule,
    ToggleButtonModule,
    InputSwitchModule,
    ListboxModule,
    CheckboxModule,
    CardModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    BreadcrumbModule,
    AutoCompleteModule,
    AvatarModule,
    RippleModule,
    SelectButtonModule,
    // Third Party
    NgxErrorsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // Developed Modules
    EnumToObjectsPipeModule,
    CountryCodeToNamePipeModule,
    EnumCaseToTextPipeModule,
    UuidPipeModule,
    FileSizePipeModule,
    ToFormControlNamePipeModule,
    CurrencySymbolPipeModule,
    ToClassNamePipeModule,
    YesNoPipeModule,
    AddressConcatPipeModule,
    DirtyOnSubmitDirectiveModule,
    SpinnerOverDirectiveModule,
    HasRoleDirectiveModule,
    PositiveNumericOnlyDirectiveModule,
    IntegerOnlyDirectiveModule,
    DecimalOnlyDirectiveModule,
    UpperLettersOnlyDirectiveModule,
    TouchAllOnSubmitDirectiveModule,
    PartnerInitialUniqueValidatorModule,
    UserEmailUniqueValidatorModule,
    VarDirectiveModule,
    // PRIME NG
    DialogModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    MenuModule,
    PanelMenuModule,
    ButtonModule,
    TieredMenuModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    ContextMenuModule,
    MessageModule,
    CalendarModule,
    DynamicDialogModule,
    SliderModule,
    DropdownModule,
    ToggleButtonModule,
    InputSwitchModule,
    ListboxModule,
    CheckboxModule,
    CardModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    BreadcrumbModule,
    AutoCompleteModule,
    AvatarModule,
    RippleModule,
    SelectButtonModule,
    // Third Party
    NgxErrorsModule
  ],
  providers: [
    ...PROVIDERS
  ],
  entryComponents: [],
  bootstrap: []
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS]
    };
  }
}
