import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from "@angular/core";
import { v4 as uuidv4 } from 'uuid';

@Pipe({ name: 'uuid', pure: true })
export class UuidPipe implements PipeTransform {
  transform(value: string): any {
      return uuidv4()
  }
}

@NgModule({
  declarations: [UuidPipe],
  exports: [UuidPipe],
})
export class UuidPipeModule {
  static forRoot(): ModuleWithProviders<UuidPipeModule> {
    return {
      ngModule: UuidPipeModule,
      providers: [UuidPipe]
    };
  }
}
