import { Selector, State } from "@ngxs/store";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, MonoTypeOperatorFunction, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Contract } from "@api/models/Contract";
import { ContractHttpService } from "@api/servies/contract-http.service";
import { ContractStateType } from "@api/models/enums/ContractStateType";
import { patch, updateItem } from "@ngxs/store/operators";
import { StudyHttpService } from "@api/servies/study-http.service";
import { Study } from "@api/models/Study";
import { CountryData } from "@api/models/CountryData";
import { ContractCreate } from "@api/models/ContractCreate";
import { AmendmentConfirmation } from "@api/models/AmendmentConfirmation";


export interface ContractStateModel {
  item: Contract;
  loading: LoadingState;
}

export const CONTRACT_STATE_DEFAULT: ContractStateModel = {
  item: new Contract(),
  loading: LoadingState.IDLE
}

@StateRepository()
@State<ContractStateModel>({
  name: 'Contract',
  defaults: CONTRACT_STATE_DEFAULT
})
@Injectable()
export class ContractState extends NgxsDataRepository<ContractStateModel> {

  constructor(private dataService: ContractHttpService,
              private studyService: StudyHttpService) {
    super();
  }

  @Selector()
  static item(state: ContractStateModel) {
    return state.item
  }

  @Selector()
  static isLoading(state: ContractStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @Selector()
  static isSaving(state: ContractStateModel) {
    return state.loading === LoadingState.SAVING;
  }

  @DataAction()
  setItem(item: Contract) {
    this.ctx.patchState({item})
  }

  @DataAction({cancelUncompleted: true})
  get(id: number) {
    this.ctx.patchState({
      ...this.initialState,
      loading: LoadingState.LOADING
    })
    return this.dataService.getById(id).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap
    )
  }

  @DataAction()
  create(contractCreate: ContractCreate) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.create(contractCreate).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  update(item: Contract) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.update(item).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  cancel(id: number, cancelReason: string) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.patch({id, state: ContractStateType.CANCELED, cancelReason}).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  uploadPolicy(file: File, policyNumber: string) {
    this.resetPolicy();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('policyNumber', policyNumber);
    return this.dataService.uploadPolicy(this.snapshot.item.id, formData).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  removePolicy() {
    this.resetPolicy();
    return this.dataService.removePolicy(this.snapshot.item.id).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  confirmAmendment(id: number, amendmentConfirmation: AmendmentConfirmation) {
    return this.dataService.confirmAmendment(id, amendmentConfirmation.sequence, amendmentConfirmation).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  updateWetInkDeliveryInfo(countryCode: string, comment: string) {
    this.ctx.setState(patch({
      item: patch({
        study: patch({
          countriesData: updateItem<CountryData>(data => data?.country.code === countryCode,
            patch({wetInkData: patch({wetInkDeliveryInfo: comment})}))
        })
      })
    }))
    return this.studyService.updateWetInkDeliveryInfo(this.getState().item.study.id, countryCode, comment).pipe(
      this.loadingIdleFinalize,
      this.studyRequestSuccessTap,
    )
  }

  private requestSuccessTap: MonoTypeOperatorFunction<Contract> = tap((item: Contract) => this.patchState({item}))

  private studyRequestSuccessTap: MonoTypeOperatorFunction<Study> = tap((study: Study) => this.ctx.setState(patch({ item: patch({ study }) })))

  private loadingIdleFinalize: MonoTypeOperatorFunction<any> = finalize(() => {
    this.patchState({loading: LoadingState.IDLE})
  })

  private resetPolicy() {
    this.ctx.setState(patch({
      item: patch<Contract>({ policy: undefined })
    }));
  }
}


