import { StudyType } from "@api/models/enums/StudyType";
import { Country } from "@api/models/Country";
import moment from 'moment'
import { MOMENT_DATE_FORMAT } from "@utils/Constants";
import { Duration } from "@api/models/Duration";
import { DurationType } from "@api/models/enums/DurationType";
import { LinkEntry } from "@api/models/LinkEntry";
import { StudyStateType } from "@api/models/enums/StudyStateType";
import { DocumentWrapper } from "@api/models/DocumentWrapper";
import { CountryData } from "@api/models/CountryData";
import { Partner } from "@api/models/Partner";
import { Offer } from "@api/models/Offer";
import { OfferStateType } from "@api/models/enums/OfferStateType";
import { StudyCountryStateType } from "@api/models/enums/StudyCountryStateType";
import { IdWrapper } from "@api/models/IdWrapper";
import { Amendment } from "@api/models/Amendment";
import { Phase } from "@api/models/enums/Phase";

export class Study {
  id!: number;
  studyId!: string;
  type: StudyType = StudyType.DRUG;
  state: StudyStateType = StudyStateType.DRAFT;
  countriesData: CountryData[] = []
  sponsor: Partner = new Partner();
  detail: StudyDetail = new StudyDetail();
  creationDate!: string;
  modificationDate!: string;
  studyProtocol?: DocumentWrapper
  patientInfo?: DocumentWrapper;
  targetInsurances: number[] = [];
  partner!: Partner;
  offers: Offer[] = [];
  contracts?: IdWrapper[] = [];
  offerState!: OfferStateType;
  offersCount: number = 0;
  stateItems: StateItem[] = [];
  amendments: Amendment[] = [];
  cancelReason?: string;
  phase: Phase = Phase.I;

  static fromForm(form: StudyForm): Study {
    let study: Study = new Study();
    study.type = form.studyType.type;
    if (form.studyRequirements?.countriesData) {
      study.countriesData = form.studyRequirements?.countriesData.map(countryData => CountryData.fromForm(countryData));
    } else {
      study.countriesData = [];
    }
    study.countriesData = form.studyRequirements?.countriesData || [];
    study.sponsor = form.studyQuestionnaire.sponsor;
    study.id = form.id;
    study.studyId = `${form.studyQuestionnaire.studyIdPrefix}${form.studyQuestionnaire.studyIdSuffix}`;
    study.detail.title = form.studyQuestionnaire.title;
    study.phase = form.studyQuestionnaire.phase;
    study.detail.protocolNumber = form.studyQuestionnaire.protocolNumber;
    study.detail.startDate = form.studyQuestionnaire.startDate ? moment(form.studyQuestionnaire.startDate).format(MOMENT_DATE_FORMAT) : undefined;
    study.detail.endDate = form.studyQuestionnaire.endDate ? moment(form.studyQuestionnaire.endDate).format(MOMENT_DATE_FORMAT) : undefined;
    study.detail.duration.type = form.studyQuestionnaire.duration.type as DurationType;
    study.detail.duration.amount = form.studyQuestionnaire.duration.amount;
    study.detail.hasFollowUpTreatment = form.studyQuestionnaire.hasFollowUpTreatment;
    study.detail.followUpDuration.type = form.studyQuestionnaire.followUpDuration.type as DurationType;
    study.detail.followUpDuration.amount = form.studyQuestionnaire.followUpDuration.amount;
    study.detail.products = Object.entries(form.studyQuestionnaire.products).map(([, value]) => value);
    study.detail.ethicsCommittee = form.studyQuestionnaire.ethicsCommittee;
    study.detail.croHelsinkiCompliant = form.studyQuestionnaire.croHelsinkiCompliant;
    study.detail.patientHelsinkiCompliant = form.studyQuestionnaire.patientHelsinkiCompliant;
    study.detail.patientConsentVerified = form.studyQuestionnaire.patientConsentVerified;
    study.detail.consentDescription = form.studyQuestionnaire.consentDescription;
    study.detail.damageHistory = form.studyQuestionnaire.damageHistory;
    study.detail.drug = form.studyQuestionnaire.drug;
    study.detail.medicalProduct = form.studyQuestionnaire.medicalProduct;
    return study;
  }

  static toForm(study: Study): StudyForm {
    return {
      id: study.id,
      studyType: {
        type: study.type
      },
      studyCountries: {
        countriesAutocomplete: (study.countriesData ? study.countriesData.map(data => data?.country) : [])
      },
      studyRequirements: {
        countriesData: study.countriesData.map(countryData => CountryData.toForm(countryData))
      },
      studyQuestionnaire: {
        studyIdPrefix: study.studyId ? study.studyId.substring(0, study.studyId.indexOf('-') + 1) : '???-',
        studyIdSuffix: study.studyId ? study.studyId.substring(study.studyId.indexOf('-') + 1) : '',
        sponsor: study.sponsor,
        title: study.detail.title,
        phase: study.phase,
        protocolNumber: study.detail.protocolNumber,
        startDate: study.detail.startDate ? moment(study.detail.startDate, MOMENT_DATE_FORMAT).toDate() : undefined,
        endDate: study.detail.endDate ? moment(study.detail.endDate, MOMENT_DATE_FORMAT).toDate() : undefined,
        duration: {
          type: study.detail.duration.type,
          amount: study.detail.duration.amount
        },
        hasFollowUpTreatment: study.detail.hasFollowUpTreatment,
        followUpDuration: {
          type: study.detail.followUpDuration.type,
          amount: study.detail.followUpDuration.amount
        },
        products: LinkEntry.toFormControls(study.detail.products),
        ethicsCommittee: study.detail.ethicsCommittee,
        croHelsinkiCompliant: study.detail.croHelsinkiCompliant,
        patientHelsinkiCompliant: study.detail.patientHelsinkiCompliant,
        patientConsentVerified: study.detail.patientConsentVerified,
        consentDescription: study.detail.consentDescription,
        damageHistory: study.detail.damageHistory,
        drug: study.detail.drug,
        medicalProduct: study.detail.medicalProduct
      },
    }
  }

  static isCancelable(study: Study): boolean {
    return StudyStateType.isActive(study.state)
    && (study?.countriesData == null || study.countriesData.filter(countryData => countryData.state === StudyCountryStateType.STARTED || countryData.state === StudyCountryStateType.COMPLETED).length === 0)
  }
}

export class StudyDetail {
  drug?: Drug;
  medicalProduct?: MedicalProduct;
  title?: string;
  protocolNumber?: string;
  startDate?: string;
  endDate?: string;
  duration: Duration = new Duration();
  hasFollowUpTreatment: boolean = true;
  followUpDuration: Duration = new Duration();
  products: LinkEntry[] = [];
  ethicsCommittee?: string;
  croHelsinkiCompliant: boolean = false;
  patientHelsinkiCompliant: boolean = false;
  patientConsentVerified: boolean = false;
  consentDescription?: string;
  damageHistory?: string;
  // additional filed to set studyId in the Study object at first step of IU form
  studyId?: string;
}

export class Drug {
  eudractNumber?: string;
}

export class MedicalProduct {
  dimdiNumber?: string;
  deviceName?: string;
  deviceType?: string;
  clinicalTested?: boolean = false;
  ceTested: boolean = false;
  ceTestedForApproval: boolean = false;
  ceTestNotApprovalReason?: string;
  invasiveTested: boolean = false;
  invasiveTestDescription?: string;
  inVitroTested: boolean = false;
  inVitroInvasiveTested: boolean = false;
  inVitroInvasiveTestDescription?: string;
  riskDescription?: string;
}

export class StateItem {
  date!: string;
  state!: StudyStateType;
}

export class StudyForm {
  id!: number
  studyType!: {
    type: StudyType
  }
  studyCountries!: {
    countriesAutocomplete: Country[]
  }
  studyRequirements?: {
    countriesData: CountryData[]
  };
  studyQuestionnaire!: {
    studyIdPrefix: string,
    studyIdSuffix?: string,
    sponsor: Partner,
    title?: string,
    phase: Phase,
    protocolNumber?: string,
    startDate?: Date,
    endDate?: Date,
    duration: {
      type: string,
      amount: number
    },
    hasFollowUpTreatment: boolean
    followUpDuration: {
      type: string,
      amount: number
    }
    products: { [key: string]: LinkEntry }
    ethicsCommittee?: string,
    croHelsinkiCompliant: boolean,
    patientHelsinkiCompliant: boolean,
    patientConsentVerified: boolean,
    consentDescription?: string;
    damageHistory?: string;
    drug?: Drug;
    medicalProduct?: MedicalProduct;
  }
}
