import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API_URL } from "@utils/Constants";
import { CustomHttpParameterCodec } from "@utils/CustomHttpParameterCodec";
import { RestPage } from "@api/models/RestPage";
import { Observable } from "rxjs";
import { PaginationLazy } from "@api/models/PaginationLazy";
import { Notification } from "@api/models/Notification";
import { GetAllNotificationsParams } from "@api/models/params/GetAllNotificationsParams";


@Injectable({providedIn: 'root'})
export class NotificationHttpService {
  private readonly URL = `${API_URL}/notifications`;

  constructor(private http: HttpClient) {}

  getAll(queryParams: GetAllNotificationsParams, pagination: PaginationLazy = new PaginationLazy()): Observable<RestPage<Notification>>  {
    let params = new HttpParams({ fromObject: {...queryParams, ...pagination}, encoder: new CustomHttpParameterCodec });
    return this.http.get<RestPage<Notification>>(`${this.URL}`, {params});
  }

  markAsRead(ids: number[]) {
    return this.http.post<void>(`${this.URL}/read`, ids);
  }

}
