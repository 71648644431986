import { Salutation } from "@api/models/enums/Salutation";

export class User {
  id?: number;
  firstName: string = '';
  lastName: string = '';
  initial?: string;
  positionTitle: string = '';
  salutation: Salutation = Salutation.UNKNOWN;
  phone: string = '';
  email: string = ''
  userName!: string;
  imageUrl?: string;
  deleted!: boolean;
}
