import { Selector, State } from "@ngxs/store";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { RestPage } from "@api/models/RestPage";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Pagination } from "@api/models/Pagination";
import { RuleHttpService } from "@api/servies/rule-http.service";
import { Rule } from "@api/models/Rule";

export interface RuleListStateModel {
  items: RestPage<Rule>;
  selected?: Rule;
  loading: LoadingState;
}

export const RULE_LIST_STATE_DEFAULT: RuleListStateModel = {
  items: new RestPage<Rule>(),
  loading: LoadingState.IDLE
}

@StateRepository()
@State<RuleListStateModel>({
  name: 'RuleList',
  defaults: RULE_LIST_STATE_DEFAULT
})
@Injectable()
export class RuleListState extends NgxsDataRepository<RuleListStateModel>{

  constructor(private dataService: RuleHttpService) {
    super();
  }

  @Selector()
  static items(state: RuleListStateModel) {
    return state.items
  }

  @Selector()
  static selected(state: RuleListStateModel) {
    return state.selected
  }

  @Selector()
  static isLoading(state: RuleListStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @DataAction({ cancelUncompleted: true })
  get(params: any, pagination: Pagination) {
    this.ctx.patchState({loading: LoadingState.LOADING})
    return this.dataService.getAll(params, pagination).pipe(
      finalize(() => { this.patchState({loading: LoadingState.IDLE})}),
      tap((items: RestPage<Rule>) => this.patchState({items}))
    )
  }

  @DataAction()
  delete(countryCode: string) {
    this.ctx.patchState({loading: LoadingState.LOADING})
    return this.dataService.delete(countryCode).pipe(
      finalize(() => { this.patchState({loading: LoadingState.IDLE})}),
    )
  }

  @DataAction()
  setSelected(selected: Rule) {
    this.ctx.patchState({ selected })
  }

}
