import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { API_URL } from "@utils/Constants";
import { CustomHttpParameterCodec } from "@utils/CustomHttpParameterCodec";
import { Pagination } from "@api/models/Pagination";
import { RestPage } from "@api/models/RestPage";
import { Rule } from "@api/models/Rule";
import { Observable } from "rxjs";



@Injectable({providedIn: 'root'})
export class RuleHttpService {
  private readonly URL = `${API_URL}/rules`;

  constructor(private http: HttpClient) {}

  getAll(params: any, pagination: Pagination = new Pagination()): Observable<RestPage<Rule>>  {
    params = new HttpParams({ fromObject: Object.assign(params, pagination), encoder: new CustomHttpParameterCodec });
    return this.http.get<RestPage<Rule>>(`${this.URL}`, {params});
  }

  getById(countryCode: string): Observable<Rule>  {
    return this.http.get<Rule>(`${this.URL}/${countryCode}`);
  }

  create(rule: Rule): Observable<Rule>  {
    return this.http.post<Rule>(`${this.URL}`, rule);
  }

  update(rule: Rule): Observable<Rule> {
    return this.http.put<Rule>(`${this.URL}/${rule.country.code}`, rule);
  }

  delete(countryCode: string): Observable<Rule> {
    return this.http.delete<Rule>(`${this.URL}/${countryCode}`);
  }

  getExistingCountry(): Observable<string[]> {
    return this.http.get<string[]>(`${this.URL}/countries`);
  }
}
