<div class="block-left">
  <a routerLink="/">
    <img src="assets/images/logo.png" alt="Clinitrix in trial logo">
  </a>
</div>

<div class="block-middle" *hasRole="Role.ADMIN">
  <a routerLink="/admin/partners" routerLinkActive="active">PARTNERS</a>
  <a routerLink="/admin/insurances" routerLinkActive="active">INSURANCES</a>
  <a routerLink="/admin/rules" routerLinkActive="active">RULES</a>
  <a routerLink="/admin/studies" routerLinkActive="active">STUDIES</a>
  <a routerLink="/admin/contracts" routerLinkActive="active">CONTRACTS</a>
</div>

<div class="block-middle" *hasAnyRole="[Role.PARTNER, Role.INSURER]">
  <a routerLink="/studies" routerLinkActive="active">STUDIES</a>
  <a routerLink="/contracts" routerLinkActive="active">CONTRACTS</a>
</div>

<div class="block-right flex align-items-center" *ngIf="authService.isAuthenticated$ | async">
  <button pButton label="Create Study" class="p-button-sm" (click)="createStudy()" *hasRole="Role.PARTNER"></button>
  <i class="pi pi-bell" (click)="notificationsPopup.toggle($event)">
    <div class="notifications-counter" *ngIf="totalUnreadNotifications$ | async as totalUnreadNotifications">{{totalUnreadNotifications < 10 ? totalUnreadNotifications : "9+"}}</div>
  </i>
  <ng-container>
    <div class="avatar-wrapper mr-4">
      <p-avatar
        [icon]="$any((avatar$ | async) ? undefined : 'pi pi-user')"
        [image]="$any(avatar$ | async)"
        (click)="menu.toggle($event)"
        size="large"
        shape="circle"
        class="flex"
        [ngClass]="{'invisible': (authService.isAuthenticated$ | async) === false}">
      </p-avatar>
      <img *ngIf="tier$ | async as tier" class="tier" [src]="'/assets/images/membership-tier/membership-tier-' + (tier | toClassName) + '.svg'" [alt]="tier | toClassName" [title]="tier | enumCaseToText">
    </div>
  </ng-container>
  <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
  <p-overlayPanel #notificationsPopup styleClass="notifications-popup">
    <ng-template pTemplate>
      <app-notifications-popup (onNotificationClick)="notificationsPopup.hide()"></app-notifications-popup>
    </ng-template>
  </p-overlayPanel>
</div>


