import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  NgModule,
  Provider,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskBaseDirective } from './mask-base.directive';
import { INTEGER_PATTERN } from "@utils/Constants";

export const MASK_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IntegerOnlyDirective),
  multi: true,
};

@Directive({
  selector: '[integerOnly]',
  providers: [MASK_VALUE_ACCESSOR],
})
export class IntegerOnlyDirective extends MaskBaseDirective {
  @HostListener('input', ['$event', '$event.target.value'])
  override onInput(event: KeyboardEvent, value: string): void {
    super.onInput(event, value);
  }

  @HostListener('blur', ['$event', '$event.target.value'])
  onBlur(event: KeyboardEvent, value: string): void {
    value = this.removePrefixZeros(value);
    this.updateInput(event, value);
  }

  override applyMask(value: any): string {
    if (INTEGER_PATTERN.test(value)) {
      return value;
    }
    value = value
      // Remove multiple zeros before int number.
      // For example: `00034 => 34'.
      .replace(/^0+(?=\d)/, '')
      // Delete all non-numeric symbols
      .replace(/\D/g, '');
    return value;
  }

  private removePrefixZeros(value: string) {
    return value
      // Remove multiple zeros before int number.
      // For example: `00034 => 34'.
      .replace(/^0+(?=\d)/, '')
  }

  constructor(
    @Inject(Renderer2) public override renderer: Renderer2,
    @Inject(ElementRef) public override element: ElementRef,
  ) {
    super(renderer, element);
  }
}

@NgModule({
  declarations: [IntegerOnlyDirective],
  exports: [IntegerOnlyDirective],
})
export class IntegerOnlyDirectiveModule {}
