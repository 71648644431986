import { Component } from '@angular/core';
import { AuthService } from "@services/auth.service";
import { SnackBarService } from "@services/snak-bar.service";
import { Role } from "@models/enums/Role";
import { MenuItem } from "primeng/api";
import { UntilDestroy } from "@ngneat/until-destroy";
import { DialogService } from "primeng/dynamicdialog";
import { StudyTypeSelectorDialogComponent } from "@components/main/shared/study-type-selector-dialog/study-type-selector-dialog.component";
import { Select } from "@ngxs/store";
import { ProfileState } from "@store";
import { filter, first, Observable, switchMap } from "rxjs";
import { Profile } from "@api/models/Profile";
import { Router } from "@angular/router";
import { NotificationListState } from "@store/notification-list.state";
import { PaginationLazy } from "@api/models/PaginationLazy";
import { MembershipTier } from "@api/models/enums/MembershipTier";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-layout-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent {
  Role = Role;

  menuItems: MenuItem[] = [
    {
      label: 'Logout',
      icon: 'fa-solid fa-arrow-right-from-bracket',
      command: () => this.authService.logout()
    }
  ]

  @Select(ProfileState.item)
  public profile$!: Observable<Profile>;
  @Select(ProfileState.tier)
  public tier$!: Observable<MembershipTier | null>;
  @Select(ProfileState.imageUrl)
  public avatar$!: Observable<string>;
  @Select(NotificationListState.totalUnread)
  public totalUnreadNotifications$!: Observable<number>;

  constructor(public authService: AuthService,
              public router: Router,
              private snackBarService: SnackBarService,
              private dialogService: DialogService,
              private notificationListState: NotificationListState) {
    this.profile$.pipe(filter(profile => !!profile.id), first()).subscribe(profile => {
      this.menuItems.unshift(
        {
          label: 'My profile',
          icon: 'pi pi-user',
          command: () => this.router.navigate(['profile', profile.id])
        },
      )
    })

    this.authService.isAuthenticated$
      .pipe(
        filter(isAuthenticated => isAuthenticated),
        first(),
        switchMap(() => this.notificationListState.get({ showAll: false }, new PaginationLazy()))
      )
      .subscribe({
        error: this.snackBarService.handleHttpError
      })
  }

  createStudy() {
    this.dialogService.open(StudyTypeSelectorDialogComponent, {
      showHeader: false,
      styleClass: 'study-type-selector-dialog',
      width: '1000px'
    })
  }

}

