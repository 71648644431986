import { Insurance } from "@api/models/Insurance";
import { LoadingState } from "@store/common/LoadingState";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { InsuranceHttpService } from "@api/servies/insurance-http.service";
import { Injectable } from "@angular/core";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Selector, State } from "@ngxs/store";
import { finalize, MonoTypeOperatorFunction, of, switchMap, tap } from "rxjs";
import { Utils } from "@utils/Utils";
import { User } from "@api/models/User";
import { patch, updateItem } from "@ngxs/store/operators";


export interface InsuranceStateModel {
  item: Insurance;
  loading: LoadingState;
  newAvatar?: string;
}

export const INSURANCE_STATE_DEFAULT: InsuranceStateModel = {
  item: new Insurance(),
  loading: LoadingState.IDLE
}


@StateRepository()
@State<InsuranceStateModel>({
  name: "Insurance",
  defaults: INSURANCE_STATE_DEFAULT
})
@Injectable()
export class InsuranceState extends NgxsDataRepository<InsuranceStateModel> {

  constructor(private dataService: InsuranceHttpService) {
    super();
  }

  @Selector()
  static item(state: InsuranceStateModel) {
    return state.item;
  }

  @Selector()
  static isLoading(state: InsuranceStateModel) {
    return state.loading == LoadingState.LOADING;
  }

  @Selector()
  static isSaving(state: InsuranceStateModel) {
    return state.loading == LoadingState.SAVING;
  }

  @Selector()
  static avatar(state: InsuranceStateModel) {
    return state.newAvatar || state.item.imageUrl;
  }

  @DataAction()
  get(id: number) {
    this.ctx.patchState({...this.initialState, loading: LoadingState.LOADING})
    return this.dataService.getById(id).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  create(item: Insurance) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.create(item).pipe(
      this.loadingIdleFinalize,
      this.updateAvatarSwitchMap,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  update(item: Insurance) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.update(item).pipe(
      this.loadingIdleFinalize,
      this.updateAvatarSwitchMap,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  changeAvatar(avatar: string) {
    this.ctx.patchState({newAvatar: avatar})
  }

  @DataAction()
  uploadAvatar(avatar: string) {
    this.ctx.patchState({newAvatar: avatar})
    const state = this.ctx.getState()
    const formData = new FormData()
    formData.append('file', Utils.dataURItoBlob(avatar))
    return this.dataService.updateImage(state.item.id as number, formData).pipe(this.requestSuccessTap)
  }

  @DataAction()
  deleteUser(userId: number, insuranceId: number) {
    return this.dataService.deleteUser(userId, insuranceId).pipe(
      tap((user: User) => this.setState(patch({item: patch({users: updateItem<User>(u => u?.id === user.id, user)})})))
    )
  }

  @DataAction()
  uploadCertificate(country: string, file: File) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    const formData = new FormData();
    formData.append('file', file);
    return this.dataService.uploadCertificate(this.snapshot.item.id as number, country, formData).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  deleteCertificate(country: string) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.deleteCertificate(this.snapshot.item.id as number, country).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  uploadTermsOfUse(country: string, file: File) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    const formData = new FormData();
    formData.append('file', file);
    return this.dataService.uploadTermOfUse(this.snapshot.item.id as number, country, formData).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  @DataAction()
  deleteTermsOfUse(country: string) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.deleteTermOfUse(this.snapshot.item.id as number, country).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  deleteDocuments(country: string) {
    this.ctx.patchState({loading: LoadingState.SAVING})
    return this.dataService.deleteDocuments(this.snapshot.item.id as number, country).pipe(
      this.loadingIdleFinalize,
      this.requestSuccessTap,
    )
  }

  private requestSuccessTap: MonoTypeOperatorFunction<Insurance> = tap((item: Insurance) => this.patchState({
    item,
    newAvatar: undefined
  }))

  private loadingIdleFinalize: MonoTypeOperatorFunction<Insurance> = finalize(() => {
    this.patchState({loading: LoadingState.IDLE})
  })

  private updateAvatarSwitchMap: MonoTypeOperatorFunction<Insurance> = switchMap((item: Insurance) => {
    const state = this.ctx.getState()
    if (state.newAvatar) {
      const formData = new FormData();
      formData.append('file', Utils.dataURItoBlob(state.newAvatar));
      return this.dataService.updateImage(item.id as number, formData)
    } else {
      return of(item);
    }
  })

}
