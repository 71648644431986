import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, filter, first, from, Observable, of, ReplaySubject, switchMap } from "rxjs";
import { Role } from "@models/enums/Role";
import { KeycloakEvent, KeycloakEventType, KeycloakService } from "keycloak-angular";
import { UserProfile } from "@models/UserProfile";
import { ProfileState } from "@store";


@Injectable({providedIn: 'root'})
export class AuthService {
  private userProfile: ReplaySubject<UserProfile | null> = new ReplaySubject(1);
  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
  public userProfile$ = this.userProfile.asObservable();
  public isAuthenticated$ = this.isAuthenticated.asObservable();

  public readonly onTokenUpdateRefreshError: Observable<KeycloakEvent> =
    this.keycloakService.keycloakEvents$
      .asObservable()
      .pipe(
        filter(({type}) => type === KeycloakEventType.OnAuthRefreshError),
      );

  constructor(
    protected keycloakService: KeycloakService,
    @Inject(Window) protected window: Window,
    private profileState: ProfileState
  ) {
    this.isLoggedIn()
      .pipe(
        first(),
      )
      .subscribe(isLoggedIn => {
        const token = this.keycloakService.getKeycloakInstance().tokenParsed;
        this.profileState.setTokenParts({name: token?.name, imageUrl: token?.image_url})
        this.profileState.getMy(token?.preferred_username, this.getRole()).subscribe({
          error: error => console.error(error)
        });
        this.isAuthenticated.next(isLoggedIn);
      });
  }

  public updateToken(minValidity?: number | undefined): Observable<boolean> {
    return from(this.keycloakService.updateToken(minValidity));
  }

  public isLoggedIn(): Observable<boolean> {
    return from(this.keycloakService.isLoggedIn());
  }

  public getProfile(): void {
    this.isLoggedIn()
      .pipe(
        first(),
        switchMap(isLoggedIn =>
          isLoggedIn
            ? from(this.keycloakService.loadUserProfile(true))
            : of(null),
        ),
      )
      .subscribe(profile => {
        this.userProfile.next(profile as UserProfile);
      });
  }

  public getUsername(): string {
    return this.keycloakService.getUsername();
  }

  public getUserEmail(): string {
    return this.keycloakService?.getKeycloakInstance()?.tokenParsed?.email;
  }

  public getRoles(): Array<Role> {
    return this.keycloakService.getUserRoles() as Array<Role>;
  }

  public hasRole(role: Role): boolean {
    return this.keycloakService.getUserRoles().includes(role);
  }

  public hasRoles(roles: Array<Role>): boolean {
    return roles.every(role =>
      this.keycloakService.getUserRoles().includes(role),
    );
  }

  public hasAnyRole(...roles: Array<Role>): boolean {
    return roles.some(role =>
      this.keycloakService.getUserRoles().includes(role),
    );
  }

  public logoutKeycloak(redirectUrl?: string | null): Promise<void> {
    return this.keycloakService.logout(
      redirectUrl || `${this.window.location.origin}`
    );
  }

  public login(redirectUri?: string): Promise<void> {
    return this.keycloakService.login({
      redirectUri: redirectUri || `${this.window.location.origin}`,
    });
  }

  public logout(redirectUri?: string): void {
    this.logoutKeycloak(redirectUri);
  }

  public isAdmin(): boolean {
    return this.hasRole(Role.ADMIN)
  }

  public isPartner(): boolean {
    return this.hasRole(Role.PARTNER)
  }

  public isInsurer(): boolean {
    return this.hasRole(Role.INSURER)
  }

  public getRole(): Role | undefined {
    if (this.isAdmin()) {
      return Role.ADMIN
    } else if (this.isPartner()) {
      return Role.PARTNER
    } else if (this.isInsurer()) {
      return Role.INSURER
    } else {
      return
    }
  }

  public getPartnerId(): string {
    return this.keycloakService.getKeycloakInstance().tokenParsed?.partner
  }

  public getInsuranceId(): string {
    return this.keycloakService.getKeycloakInstance().tokenParsed?.insurance
  }

}
