import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from "@services/auth.service";
import { map, Observable } from "rxjs";
import { Role } from "@models/enums/Role";


@Injectable({providedIn: 'root'})
export class NotAuthenticatedGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess();
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess();
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess();
  }

  hasAccess(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn().pipe(map(isLoggedIn => {
      if (isLoggedIn) {
        if (this.authService.hasRole(Role.ADMIN)) {
          this.router.navigate(['admin/dashboard']);
        } else if (this.authService.hasRole(Role.PARTNER)) {
          this.router.navigate(['dashboard']);
        } else if (this.authService.hasRole(Role.INSURER)) {
          this.router.navigate(['dashboard']);
        }
      }
      return !isLoggedIn
    }))
  }
}
