import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

import { environment } from '@env/environment';

export function keycloakInitializer(
  keycloakService: KeycloakService,
): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return keycloakService.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/assets/keycloak/silent-check-sso.html`,
        pkceMethod: 'S256',
        enableLogging: false,
      },
      bearerExcludedUrls: ['/assets'],
    }).then(inited => {keycloakService.keycloakEvents$.subscribe({
        next: (e) => {
          if (e.type == KeycloakEventType.OnTokenExpired) {
            keycloakService.updateToken(20);
          }
        }
      });
      return true;
    });
  };
}
