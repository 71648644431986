import { map, Observable, of, } from 'rxjs';
import { Directive, Injectable, Input, ModuleWithProviders, NgModule } from "@angular/core";
import {
  AbstractControl,
  AsyncValidator,
  AsyncValidatorFn,
  NG_ASYNC_VALIDATORS,
  ValidationErrors
} from "@angular/forms";
import { StaticInjector } from "@utils/Injector";
import { UserHttpService } from "@api/servies/user-http.service";


@Injectable()
@Directive({
  selector: '[userEmailUnique]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UserEmailUniqueValidator, multi: true}]
})
export class UserEmailUniqueValidator implements AsyncValidator {
  private static readonly ERROR_KEY = 'unique'
  private static readonly ERROR = {[UserEmailUniqueValidator.ERROR_KEY]: true}
  @Input() userEmailUnique?: string

  constructor(private userHttpsService: UserHttpService) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return UserEmailUniqueValidator.check(control, this.userEmailUnique, this.userHttpsService)
  }

  static fn(initialValue?: string): AsyncValidatorFn {
    return (control: AbstractControl) => this.check(control, initialValue, StaticInjector.instance?.get(UserHttpService));
  }

  private static check(control: AbstractControl, initialValue?: string, httpService?: UserHttpService): Observable<ValidationErrors | null> {
    if (!control.value || control.value === initialValue) return of(null);
    if (!httpService) return of(UserEmailUniqueValidator.ERROR)
    return httpService.isEmailOccupied(control.value)
      .pipe(map(result => result ? UserEmailUniqueValidator.ERROR : null))
  }
}

@NgModule({
  declarations: [UserEmailUniqueValidator],
  exports: [UserEmailUniqueValidator],
})
export class UserEmailUniqueValidatorModule {
  static forRoot(): ModuleWithProviders<UserEmailUniqueValidatorModule> {
    return {
      ngModule: UserEmailUniqueValidatorModule,
      providers: [UserEmailUniqueValidator]
    };
  }
}
