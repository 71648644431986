import { CountryISO } from "ngx-intl-tel-input";

export const API_URL = "/api/v1";
export const USER_KEY = 'user';
export const MOMENT_ZONED_DATE_TIME_FORMAT = 'yyyy-MM-DDTHH:mm:ssZ';
export const MOMENT_DATE_TIME_FORMAT = 'yyyy-MM-DDTHH:mm:ss';
export const MOMENT_DATE_FORMAT = 'yyyy-MM-DD';
export const MOMENT_HOURS_MINUTES_FORMAT = 'HH:mm';
export const EMAIL_PATTERN = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
export const DECIMAL_PATTERN: RegExp = /^\d+(\.\d{1,2})?$/;
export const INTEGER_PATTERN: RegExp = /^([1-9]\d*|0)$/;
export const LOCALE = 'de-DE';
export const DEFAULT_PHONE_COUNTRY_ISO = CountryISO.Germany;
export const MAX_FILE_SIZE = 5242880;

export const DEFAULT_OFFER_EXPIRATION_DATE_YEARS = 1;
