import { Component, Injector, OnInit } from '@angular/core';
import { OverwritePrimeNgService } from "@services/overwrite-primeng.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { StaticInjector } from "@utils/Injector";
import { NotificationService } from "@services/notification.service";
import { CustomStompService } from "@services/custom-stomp.service";
import { AuthService } from "@services/auth.service";
import { NgcCookieConsentService } from "ngx-cookieconsent";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(private overwritePrimeNgService: OverwritePrimeNgService,
              private injector: Injector,
              private stompService: CustomStompService,
              private notificationService: NotificationService,
              private authService: AuthService,
              // injection required to init cookieConsentService
              private cookieConsentService: NgcCookieConsentService) {
    StaticInjector.instance = injector;
    this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) this.stompService.startBroker()
    })
    this.notificationService.subscribeOnNotifications();
  }

  ngOnInit(): void {
    this.overwritePrimeNgService.overridePrimeNgTableColumnFilter()
  }
}
