import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  NgModule,
  Provider,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskBaseDirective } from './mask-base.directive';

export const MASK_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UpperLettersOnlyDirective),
  multi: true,
};

@Directive({
  selector: '[upperLettersOnly]',
  providers: [MASK_VALUE_ACCESSOR],
})
export class UpperLettersOnlyDirective extends MaskBaseDirective {
  @HostListener('input', ['$event', '$event.target.value'])
  override onInput(event: KeyboardEvent, value: string): void {
    super.onInput(event, value);
  }

  @HostListener('blur', ['$event', '$event.target.value'])
  onBlur(event: KeyboardEvent, value: string): void {
    this.updateInput(event, this.applyMask(value));
  }

  override applyMask(value: any): string {
    if (/^[A-Z]+$/.test(value)) {
      return value;
    }
    value = value
      .toString()
      .toUpperCase()
      .replace(/[^A-Z]/g, '');
    return value;
  }

  constructor(
    @Inject(Renderer2) public override renderer: Renderer2,
    @Inject(ElementRef) public override element: ElementRef,
  ) {
    super(renderer, element);
  }
}

@NgModule({
  declarations: [UpperLettersOnlyDirective],
  exports: [UpperLettersOnlyDirective],
})
export class UpperLettersOnlyDirectiveModule {}
