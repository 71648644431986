import { Partner } from "@api/models/Partner";
import { Selector, State } from "@ngxs/store";
import { PartnerHttpService } from "@api/servies/partner-http.service";
import { LoadingState } from "@store/common/LoadingState";
import { finalize, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { RestPage } from "@api/models/RestPage";
import { NgxsDataRepository } from "@angular-ru/ngxs/repositories";
import { DataAction, StateRepository } from "@angular-ru/ngxs/decorators";
import { Pagination } from "@api/models/Pagination";
import { patch, updateItem } from "@ngxs/store/operators";
import { AuditTrail } from "@api/models/AuditTrail";
import { ContractHttpService } from "@api/servies/contract-http.service";

export interface AuditTrailListStateModel {
  items: AuditTrail[];
  loading: LoadingState;
}

export const AUDIT_TRAIL_LIST_STATE_DEFAULT: AuditTrailListStateModel = {
  items: [],
  loading: LoadingState.IDLE
}

@StateRepository()
@State<AuditTrailListStateModel>({
  name: 'AuditTrail',
  defaults: AUDIT_TRAIL_LIST_STATE_DEFAULT
})
@Injectable()
export class AuditTrailListState extends NgxsDataRepository<AuditTrailListStateModel> {

  constructor(private dataService: ContractHttpService) {
    super();
  }

  @Selector()
  static items(state: AuditTrailListStateModel) {
    return state.items
  }

  @Selector()
  static isLoading(state: AuditTrailListStateModel) {
    return state.loading === LoadingState.LOADING;
  }

  @DataAction({cancelUncompleted: true})
  getAll(contractId: number) {
    this.ctx.patchState({loading: LoadingState.LOADING})
    return this.dataService.getAudit(contractId).pipe(
      finalize(() => this.patchState({loading: LoadingState.IDLE})),
      tap((items: AuditTrail[]) => this.patchState({items}))
    )
  }
}
