import { Injectable } from "@angular/core";
import { ColumnFilterFormElement } from "primeng/table";

@Injectable({
  providedIn: 'root'
})
export class OverwritePrimeNgService {

  constructor() {
    this.overridePrimeNgTableColumnFilter()
  }

  /** Monkey-patch for PrimeNG table. Fire lazy event on typing */
  overridePrimeNgTableColumnFilter() {
    ColumnFilterFormElement.prototype.onModelChange = function (value) {
      this.filterConstraint.value = value;
      if (this.type || value === '') {
        this.dt._filter();
      }
    }
  }
}
