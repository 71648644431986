<div class="p-dialog-header ng-tns-c119-4 ng-star-inserted">
  <span class="p-dialog-title flex">
    <span>Choose the type of a clinical trial that is subject to compulsory insurance</span>
  </span>
  <div class="p-dialog-header-icons ng-tns-c119-4">
    <button type="button" class="ng-tns-c119-4 p-dialog-header-icon p-dialog-header-maximize p-link ng-star-inserted" (click)="ref.close()">
      <span class="p-dialog-header-close-icon pi pi-times ng-tns-c119-4"></span>
    </button>
  </div>
</div>

<div class="body type-selector">
  <div class="type" [ngClass]="{'active': formGroup.controls.type.value == StudyType.DRUG}" (click)="formGroup.controls.type.patchValue(StudyType.DRUG)">
    <div class="check-circle"></div>
    <div class="text">Drugs</div>
    <i class="zgis-icon-drugs"></i>
  </div>
  <div class="type" [ngClass]="{'active': formGroup.controls.type.value == StudyType.MEDICAL_PRODUCT}" (click)="formGroup.controls.type.patchValue(StudyType.MEDICAL_PRODUCT)">
    <div class="check-circle"></div>
    <div class="text">Medical Product</div>
    <i class="zgis-icon-medical-sign"></i>
  </div>
</div>

<button pButton pRipple type="button" label="Create Study" class="p-button-sm" (click)="createStudy()"></button>

