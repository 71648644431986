import { Inject, Injectable } from '@angular/core';

import { InjectableRxStompConfig, RxStompService } from '@stomp/ng2-stompjs';
import { RxStompState } from '@stomp/rx-stomp';
import { IFrame } from '@stomp/stompjs';
import { KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '@env/environment';
import { SnackBarService } from "@services/snak-bar.service";

@Injectable({ providedIn: 'root' })
export class CustomStompService extends RxStompService {
  private static readonly INVALID_TOKEN_ERROR = '403 Valid token required';
  private static readonly TOKEN_EXPIRED_ERROR = '401 Token expired';
  private static readonly ACCESS_DENIED_EXCEPTION_PART =
    'AccessDeniedException';
  private static readonly RECONNECT_DELAY = 1000;

  private readonly wsEndpoint = `${
    this.window.location.protocol === 'https:' ? 'wss' : 'ws'
  }://${this.window.location.host}/websocket`;


  private readonly initialConfig: InjectableRxStompConfig = {
    brokerURL: this.wsEndpoint,
    connectHeaders: {},
    heartbeatIncoming: 0, // Typical value 0 - disabled
    heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds
    reconnectDelay: 3000, // Disabled, we do the manual reconnect through this.reconnectAfter()
    // debug: msg => {
    //   if (!environment.production) {
    //     console.debug(new Date(), msg);
    //   }
    // },
  };

  private initialized: boolean = false;

  public constructor(
    @Inject(Window) protected window: Window,
    private snackBarService: SnackBarService,
    private keycloakService: KeycloakService,
  ) {
    super();

    this.webSocketErrors$.subscribe(error => {
      console.error(error);
    });

    this.unhandledReceipts$.subscribe(error => {
      console.error(error);
    });

    this.unhandledFrame$.subscribe(error => {
      console.error(error);
    });

    this.unhandledMessage$.subscribe(error => {
      console.error(error);
    });

    this.connectionState$.subscribe((state: RxStompState) => {
      if (state === RxStompState.CLOSED && this.initialized) {
        this.reconnectAfter(CustomStompService.RECONNECT_DELAY);
      }
    });

    this.stompErrors$.subscribe((iframe: IFrame) => {
      let msg: string | null = null;
      if (typeof iframe === 'object') {
        msg = iframe.command === 'ERROR' ? iframe.headers.message : iframe.body;
      }
      console.error(msg);
      if (msg?.startsWith(CustomStompService.TOKEN_EXPIRED_ERROR)) {
        // console.warn('token expired, reconnecting ...');
        // this.reconnectAfter(100);
      } else if (
        msg?.startsWith(CustomStompService.INVALID_TOKEN_ERROR) ||
        msg?.includes(CustomStompService.ACCESS_DENIED_EXCEPTION_PART)
      ) {
        console.error(msg);
        this.shutDown();
        this.snackBarService.showError('Access Denied to message broker');
      } else {
        console.error(msg);
        this.shutDown();
        this.snackBarService.showError(
          'Unexpected error during connecting to message broker',
        );
      }
    });
  }

  private reconnectAfter(ms: number): void {
    this.delay(ms).then(() => {
      from(this.keycloakService.isLoggedIn())
        .pipe(first())
        .subscribe(isLoggedIn => {
          if (isLoggedIn) {
            this.startBroker();
          }
        });
    });
  }

  async delay(ms: number): Promise<void> {
    await new Promise(resolve => setTimeout(() => resolve(null), ms)).then(
      () => {},
    );
  }

  public shutDown(): void {
    this.initialized = false;
    this.deactivate();
  }

  startBroker(): void {
    from(this.keycloakService.getToken())
      .pipe(first())
      .subscribe(token => {
        this.initialized = true;
        this.initialConfig.connectHeaders = {
          authorization: `Bearer ${token}`,
        };
        this.configure(this.initialConfig);
        this.activate();
      });
  }
}
