import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { API_URL } from "@utils/Constants";
import { Pagination } from "@api/models/Pagination";
import { map, Observable } from "rxjs";
import { RestPage } from "@api/models/RestPage";
import { Insurance } from "@api/models/Insurance";
import { CustomHttpParameterCodec } from "@utils/CustomHttpParameterCodec";
import { User } from "@api/models/User";

@Injectable({providedIn: "root"})
export class InsuranceHttpService {

  private readonly URL = `${API_URL}/insurances`

  constructor(private http: HttpClient) {
  }

  getAll(params: any, pagination: Pagination): Observable<RestPage<Insurance>> {
    params = new HttpParams({fromObject: Object.assign(params, pagination), encoder: new CustomHttpParameterCodec()})
    return this.http.get<RestPage<Insurance>>(this.URL, {params})
  }

  getById(id: number): Observable<Insurance> {
    return this.http.get<Insurance>(`${this.URL}/${id}`);
  }

  create(insurance: Insurance): Observable<Insurance> {
    return this.http.post<Insurance>(`${this.URL}`, insurance);
  }

  update(insurance: Insurance): Observable<Insurance> {
    return this.http.put<Insurance>(`${this.URL}/${insurance.id}`, insurance);
  }

  updateImage(id: number, formData: FormData): Observable<Insurance> {
    return this.http.put<Insurance>(`${this.URL}/${id}/image`, formData);
  }

  getUsers(insuranceId: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.URL}/${insuranceId}/users`);
  }

  getUser(id: number, insuranceId: number): Observable<User> {
    return this.http.get<User>(`${this.URL}/${insuranceId}/users/${id}`);
  }

  createUser(user: User, insuranceId: number): Observable<User> {
    return this.http.post<User>(`${this.URL}/${insuranceId}/users`, user);
  }

  updateUser(user: User, insuranceId: number): Observable<User> {
    return this.http.put<User>(`${this.URL}/${insuranceId}/users/${user.id}`, user);
  }

  updateOwn(user: User, insuranceId: number): Observable<User> {
    return this.http.put<User>(`${this.URL}/${insuranceId}/users/my`, user);
  }

  deleteUser(id: number, insuranceId: number): Observable<User> {
    return this.http.delete<User>(`${this.URL}/${insuranceId}/users/${id}`);
  }

  updateUserImage(id: number, insuranceId: number, formData: FormData): Observable<User> {
    return this.http.put<User>(`${this.URL}/${insuranceId}/users/${id}/image`, formData);
  }

  getMy(): Observable<Insurance> {
    return this.http.get<Insurance>(`${this.URL}/my`);
  }

  getCertificate(insuranceId: number, country: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.URL}/${insuranceId}/certificates/${country}`,{responseType: 'blob', observe: 'response'})
  }

  uploadCertificate(insuranceId: number, country: string, formData: FormData): Observable<Insurance> {
    return this.http.put<Insurance>(`${this.URL}/${insuranceId}/certificates/${country}`, formData)
  }

  deleteCertificate(insuranceId: number, country: string): Observable<Insurance> {
    return this.http.delete<Insurance>(`${this.URL}/${insuranceId}/certificates/${country}`)
  }

  getCertificatePreview(insuranceId: number, country: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.URL}/${insuranceId}/certificates/${country}/preview`, {responseType: 'blob', observe: 'response'});
  }

  getTermOfUse(insuranceId: number, country: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.URL}/${insuranceId}/termsOfUse/${country}`,{responseType: 'blob', observe: 'response'})
  }

  uploadTermOfUse(insuranceId: number, country: string, formData: FormData): Observable<Insurance> {
    return this.http.put<Insurance>(`${this.URL}/${insuranceId}/termsOfUse/${country}`, formData)
  }

  deleteTermOfUse(insuranceId: number, country: string): Observable<Insurance> {
    return this.http.delete<Insurance>(`${this.URL}/${insuranceId}/termsOfUse/${country}`)
  }

  deleteDocuments(insuranceId: number, country: string) {
    return this.http.delete<Insurance>(`${this.URL}/${insuranceId}/documents/${country}`)
  }
}
