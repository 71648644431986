export class PaginationLazy {
  offset: any;
  size: any;
  sort?: any;

  constructor(offset: any = 0, size: any = 10, sort: any = '') {
    this.offset = offset;
    this.size = size;
    this.sort = sort;
  }

  static nextPart(pagination: PaginationLazy) {
    let nextPart: PaginationLazy = { ...pagination }
    nextPart.offset += nextPart.size;
    return nextPart;
  }
}
