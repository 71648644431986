export class LinkEntry {
  name!: string;
  relevant: boolean = false;
  description?: string;

  static toFormControls(linkEntries: LinkEntry[]) {
    return linkEntries.reduce((reduced, next): any => {
      let formControlName = next.name.toLowerCase().replace(/[^a-zA-Z ]/g, ' ').replace(/\s|\s+/g, '')
      reduced[formControlName] = next
      return reduced;
    }, {} as {[key: string]: LinkEntry})
  }
}

export const SUBSTANCE_LINKS: LinkEntry[] = [
  {relevant: true, name: 'Accutane'},
  {relevant: true, name: 'Diethystilbestrol(DES)'},
  {relevant: true, name: 'L-trytophan'},
  {relevant: true, name: 'Phen/fen/dexfan'},
  {relevant: true, name: 'Phenylpropanolamines'},
  {relevant: true, name: 'Thalidomides'},
  {relevant: true, name: 'Contraceptives'},
  {relevant: true, name: 'Swine flu'},
  {relevant: true, name: 'Lead'},
  {relevant: true, name: 'Thimerosal'},
  {relevant: true, name: 'Quicksiber'},
  {relevant: true, name: 'Ephedrine'},
  {relevant: true, name: 'Fenfluramine'},
  {relevant: true, name: 'Fentermine or dexfenfluramine'},
  {relevant: true, name: 'Phenylpropanolamine (PPA)'},
  {relevant: true, name: 'Hydrochloride'},
  {relevant: true, name: 'Cisapride'},
  {relevant: true, name: 'Diethylhexyl phthalate (DEHP)'},
  {relevant: true, name: 'Mercury'},
  {relevant: true, name: 'Phentermine'},
  {relevant: true, name: 'Kaya (Piper methysticum)'},
  {relevant: true, name: 'Trogli-tazone'},
  {relevant: true, name: 'Latex'},
  {relevant: true, name: 'Bupropion'},
  {relevant: true, name: 'Diazepines/ oxazepines/ thiazepines'},
  {relevant: true, name: 'Dexfenfluramine'},
  {relevant: true, name: 'Risperidone'},
  {relevant: true, name: 'Remoxipride'},
  {relevant: true, name: 'Hydro-quinone'},
  {relevant: true, name: 'HMG CoA reductase inhibitors'},
  {relevant: true, name: 'Retinoids'},
  {relevant: true, name: 'Fibrin acid derivatives'},
  {relevant: true, name: 'Potency medicines'},
  {relevant: true, name: 'Biosimilars'},
  {relevant: true, name: 'Cox-2 inhibitors'},
  {relevant: true, name: 'Neuro-muscular blockers'},
  {relevant: true, name: 'Anticonvulsants'},
  {relevant: true, name: 'Dopaminergic medicines'},
  {relevant: true, name: 'Hydroxyquinoline derivatives'},
  {relevant: true, name: 'Bisphenol A (BPA)'},
  {relevant: true, name: 'Sero-tonin reuptake inhibitors (Selective Serotonin Reuptake Inhibitor products (SSRI))'},
]

export const PRODUCT_LINKS = [
  {relevant: true, name: 'Animal origin'},
  {relevant: true, name: 'Oral contraceptives'},
  {relevant: true, name: 'Vaccines'},
  {relevant: true, name: 'Slimming agents'},
  {relevant: true, name: 'Psychoactive products'},
  {relevant: true, name: 'Products with known mutagenicity'},
  {relevant: true, name: 'Products with known carcinogenicity'},
  {relevant: true, name: 'Implants'},
]
