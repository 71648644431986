import { RouterModule, Routes } from '@angular/router';
import { LayoutMainComponent } from "./layout/layout-main/layout-main.component";
import { LayoutAdminComponent } from "./layout/layout-admin/layout-admin.component";
import { NotAuthenticatedGuard } from "@guards/not-authenticated.guard";
import { AdminGuard } from "@guards/admin.guard";
import { UserGuard } from "@guards/user.guard";
import { LayoutDefaultComponent } from "./layout/layout-default/layout-default.component";
import { PartnerGuard } from "@guards/partner.guard";

const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@components/shared/home/home.component').then(m => m.HomeModule),
        canActivate: [NotAuthenticatedGuard]
      }
    ]
  },
  {
    path: '',
    component: LayoutMainComponent,
    canActivate: [UserGuard],
    children: [
      {
        path: 'profile/:profileId',
        loadChildren: () => import('@components/main/profile/profile.component').then(m => m.ProfileModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('@components/main/dashboard/dashboard.component').then(m => m.DashboardModule)
      },
      {
        path: 'studies',
        loadChildren: () => import('@components/main/studies/study-list/study-list.component').then(m => m.StudyListModule)
      },
      {
        path: 'studies/item',
        loadChildren: () => import('@components/main/studies/study-item/study-item.component').then(m => m.StudyItemModule)
      },
      {
        path: 'studies/view',
        loadChildren: () => import('@components/main/studies/study-view/study-view.component').then(m => m.StudyViewModule)
      },
      {
        path: 'studies/:studyId/offers/item',
        loadChildren: () => import('@components/main/offers/offer-item/offer-item.component').then(m => m.OfferItemModule)
      },
      {
        path: 'studies/:studyId/offers',
        loadChildren: () => import('@components/main/offers/offers-comparator/offers-comparator.component').then(m => m.OffersComparatorModule),
        canActivate: [PartnerGuard]
      },
      {
        path: 'contracts',
        loadChildren: () => import('@components/main/contracts/contract-list/contract-list.component').then(m => m.ContractListModule)
      },
      {
        path: 'contracts/item',
        loadChildren: () => import('@components/main/contracts/contract-item/contract-item.component').then(m => m.ContractItemModule)
      },
    ]
  },
  {
    path: 'admin',
    component: LayoutAdminComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@components/admin/admin-dashboard/admin-dashboard.component').then(m => m.AdminDashboardModule)
      },
      {
        path: 'partners',
        loadChildren: () => import('@components/admin/partners/partner-list/partner-list.component').then(m => m.PartnerListModule)
      },
      {
        path: 'partners/item',
        loadChildren: () => import('@components/admin/partners/partner-item/partner-item.component').then(m => m.PartnerItemModule)
      },
      {
        path: 'partners/view',
        loadChildren: () => import('@components/admin/partners/partner-view/partner-view.component').then(m => m.PartnerViewModule)
      },
      {
        path: 'partners/:partnerId/users/item',
        loadChildren: () => import('@components/admin/partners/users/partner-user-item/partner-user-item.component').then(m => m.PartnerUserItemModule)
      },
      {
        path: 'partners/:partnerId/users/view',
        loadChildren: () => import('@components/admin/partners/users/partner-user-view/partner-user-view.component').then(m => m.PartnerUserViewModule)
      },
      {
        path: 'insurances',
        loadChildren: () => import('@components/admin/insurances/insurance-list/insurance-list.component').then(m => m.InsuranceListModule)
      },
      {
        path: 'insurances/item',
        loadChildren: () => import('@components/admin/insurances/insurance-item/insurance-item.component').then(m => m.InsuranceItemModule)
      },
      {
        path: 'insurances/view',
        loadChildren: () => import('@components/admin/insurances/insurance-view/insurance-view.component').then(m => m.InsuranceViewModule)
      },
      {
        path: 'insurances/:insuranceId/users/item',
        loadChildren: () => import('@components/admin/insurances/users/insurance-user-item/insurance-user-item.component').then(m => m.InsuranceUserItemModule)
      },
      {
        path: 'insurances/:insuranceId/users/view',
        loadChildren: () => import('@components/admin/insurances/users/insurance-user-view/insurance-user-view.component').then(m => m.InsuranceUserViewModule)
      },
      {
        path: 'rules',
        loadChildren: () => import('@components/admin/rules/rule-list/rule-list.component').then(m => m.RuleListModule)
      },
      {
        path: 'rules/item',
        loadChildren: () => import('@components/admin/rules/rule-item/rule-item.component').then(m => m.RuleItemModule)
      },
      {
        path: 'studies',
        loadChildren: () => import('@components/admin/studies/study-list/study-list.component').then(m => m.StudyListModule)
      },
      {
        path: 'contracts',
        loadChildren: () => import('@components/admin/contracts/contract-list/contract-list.component').then(m => m.ContractListModule)
      },
    ]
  }
];

export const routing = RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
  useHash: false,
  scrollPositionRestoration: 'enabled'
});

