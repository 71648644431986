import {
  Directive,
  ElementRef,
  forwardRef,
  Inject,
  NgModule,
  Provider,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskBaseDirective } from './mask-base.directive';

export const MASK_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PositiveNumericOnlyDirective),
  multi: true,
};

@Directive({
  selector: '[positiveNumericOnly]',
  host: {
    '(input)': 'onInput($event, $event.target.value)',
  },
  providers: [MASK_VALUE_ACCESSOR],
})
export class PositiveNumericOnlyDirective extends MaskBaseDirective {
  override applyMask(value: any): any {
    value = value.toString().replace(/\D/g, '');
    if (value.length === 0 || value[0] === 0) {
      return '';
    }
    return value;
  }

  constructor(
    @Inject(Renderer2) public override renderer: Renderer2,
    @Inject(ElementRef) public override element: ElementRef,
  ) {
    super(renderer, element);
  }
}

@NgModule({
  declarations: [PositiveNumericOnlyDirective],
  exports: [PositiveNumericOnlyDirective],
})
export class PositiveNumericOnlyDirectiveModule {}
