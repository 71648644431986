import { AccountType } from "@api/models/enums/AccountType";

export class Account {
  type: AccountType = AccountType.SEPA;
  holderName: string = '';
  iban: string = '';
  bic: string = '';
  accountNumber: string = '';
  swift: string = '';
  bank: string = '';
}
