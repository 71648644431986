import { PartnerState } from "@store/partner.state";
import { PartnerListState } from "@store/partner-list.state";
import { PartnerUserState } from "@store/partner-user.state";
import { StudyState } from "@store/study.state";
import { InsuranceListState } from "@store/insurance-list.state";
import { InsuranceState } from "@store/insurance.state";
import { InsuranceUserState } from "@store/insurance-user.state";
import { RuleListState } from "@store/rule-list.state";
import { RuleState } from "@store/rule.state";
import { StudyListState } from "@store/study-list.state";
import { ProfileState } from "@store/profile.state";
import { OfferState } from "@store/offer.state";
import { ContractListState } from "@store/contract-list.state";
import { ContractState } from "@store/contract.state";
import { NotificationListState } from "@store/notification-list.state";
import { NavigationExtrasState } from "@store/navigation-extras.state";
import { AuditTrailListState } from "@store/audit-trail-list.state";

export const AppStates = [
  PartnerState,
  PartnerListState,
  PartnerUserState,
  InsuranceListState,
  InsuranceState,
  InsuranceUserState,
  RuleState,
  RuleListState,
  StudyState,
  StudyListState,
  ProfileState,
  OfferState,
  ContractState,
  ContractListState,
  AuditTrailListState,
  NotificationListState,
  NavigationExtrasState,
];

export * from './partner.state';
export * from './partner-list.state';
export * from './partner-user.state';
export * from './insurance-list.state';
export * from './insurance.state';
export * from './insurance-user.state';
export * from './rule.state';
export * from './rule-list.state';
export * from './study.state';
export * from './study-list.state';
export * from './profile.state';
export * from './offer.state';
export * from './contract.state';
export * from './contract-list.state';
export * from './audit-trail-list.state';
export * from './notification-list.state';
export * from './navigation-extras.state';



