import { Country } from "@api/models/Country";
import { Amount } from "@api/models/Amount";
import { Duration } from "@api/models/Duration";
import { Tax } from "@api/models/Tax";
import { TestCenter } from "@api/models/TestCenter";
import { CountryDocument } from "@api/models/CountryDocument";
import { Investigator } from "@api/models/Investigator";
import { StudyCountryStateType } from "@api/models/enums/StudyCountryStateType";
import { WetInkData } from "@api/models/WetInkData";
import moment from "moment/moment";
import { MOMENT_DATE_FORMAT } from "@utils/Constants";

export class CountryData {
  country!: Country
  perClaimLimit: Amount = new Amount()
  aggregatedLimit: Amount = new Amount()
  erp: Duration = new Duration()
  taxes: Tax[] = []
  testCenters: TestCenter[] = []
  investigators?: Investigator[];
  localTitle?: string;
  documents: CountryDocument[] = []
  testPersons?: number;
  randomizedTestPersons?: number;
  startDate?: string;
  endDate?: string;
  firstPatientIn?: string;
  lastPatientOut?: string;
  state: StudyCountryStateType = StudyCountryStateType.DRAFT;
  cancelReason?: string;
  wetInkData = new WetInkData();
  coverageCustomized = false;
  travelInsuranceRequested: boolean = false;

  public static of(country: Country): CountryData {
    let countryData: CountryData = new CountryData()
    countryData.country = country;
    return countryData;
  }

  public static fromForm(countryData: CountryData) {
    countryData = {...countryData}
    countryData.startDate = countryData.startDate ? moment(countryData.startDate).format(MOMENT_DATE_FORMAT) : undefined
    countryData.endDate = countryData.endDate ? moment(countryData.endDate).format(MOMENT_DATE_FORMAT) : undefined
    countryData.firstPatientIn = countryData.firstPatientIn ? moment(countryData.firstPatientIn).format(MOMENT_DATE_FORMAT) : undefined
    countryData.lastPatientOut = countryData.lastPatientOut ? moment(countryData.lastPatientOut).format(MOMENT_DATE_FORMAT) : undefined
    return countryData;
  }

  public static toForm(countryData: CountryData) {
    countryData = {...countryData}
    countryData.startDate = countryData.startDate ? moment(countryData.startDate, MOMENT_DATE_FORMAT).toDate() as any : undefined;
    countryData.endDate = countryData.endDate ? moment(countryData.endDate, MOMENT_DATE_FORMAT).toDate() as any : undefined;
    countryData.firstPatientIn = countryData.firstPatientIn ? moment(countryData.firstPatientIn, MOMENT_DATE_FORMAT).toDate() as any : undefined;
    countryData.lastPatientOut = countryData.lastPatientOut ? moment(countryData.lastPatientOut, MOMENT_DATE_FORMAT).toDate() as any : undefined;
    countryData.wetInkData = countryData.wetInkData || new WetInkData()
    return countryData;
  }
}
