import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'toClassName'})
export class ToClassNamePipe implements PipeTransform {
  transform(value?: string): any {
    return value ? value.replace(/ /g, "-").replace(/_/g, "-").toLowerCase() : ''
  }
}

@NgModule({
  declarations: [ToClassNamePipe],
  exports: [ToClassNamePipe],
})
export class ToClassNamePipeModule {
  static forRoot(): ModuleWithProviders<ToClassNamePipeModule> {
    return {
      ngModule: ToClassNamePipeModule,
      providers: [ToClassNamePipe]
    };
  }
}
