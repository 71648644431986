import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  NgModule,
  Provider,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskBaseDirective } from './mask-base.directive';
import { DECIMAL_PATTERN } from "@utils/Constants";

export const MASK_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DecimalOnlyDirective),
  multi: true,
};

@Directive({
  selector: '[decimalOnly]',
  providers: [MASK_VALUE_ACCESSOR],
})
export class DecimalOnlyDirective extends MaskBaseDirective {
  @HostListener('input', ['$event', '$event.target.value'])
  override onInput(event: KeyboardEvent, value: string): void {
    super.onInput(event, value);
  }

  @HostListener('blur', ['$event', '$event.target.value'])
  onBlur(event: KeyboardEvent, value: string): void {
    value = value
      // Delete extra leading and trailing zeros. For example `0045.00 => 45`.
      .replace(/(^0+(?!\.)(?=\d))|(\.0{0,2}$)/g, '')
      // Replace multiple zeros with single zero before decimal point.
      // For example: `000.34 => 0.34'.
      .replace(/^0+(?=\.)/, '0');
    this.updateInput(event, value);
  }

  override applyMask(value: any): string {
    if (DECIMAL_PATTERN.test(value)) {
      return value;
    }
    value = value
      .toString()
      // Replace all comma with point.
      .replace(/,+/g, '.')
      // Delete all non-numeric symbols and leading points.
      .replace(/[^\d.]+|^\.+/g, '')
      // Delete all extra points.
      .replace(/(\.\d*)(\.+)/g, '$1')
      // Delete all extra numbers after decimal point.
      .replace(/(\d+\.\d{2})(.+)/g, '$1');

    return value;
  }

  constructor(
    @Inject(Renderer2) public override renderer: Renderer2,
    @Inject(ElementRef) public override element: ElementRef,
  ) {
    super(renderer, element);
  }
}

@NgModule({
  declarations: [DecimalOnlyDirective],
  exports: [DecimalOnlyDirective],
})
export class DecimalOnlyDirectiveModule {}
