import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SnackBarService } from "@services/snak-bar.service";
import { Router } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { ProfileState } from "@store";



@Injectable()
export class AccessDeniedInterceptor implements HttpInterceptor {
  private errorHandler: ErrorHandler;

  /**
   * Injector needs to get ErrorHandler
   * because angular way injection making Cyclic dependency
   */
  constructor(private router: Router, private sna: SnackBarService, private injector: Injector, private authService: AuthService, private profileState: ProfileState) {
    this.errorHandler = this.injector.get(ErrorHandler);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 403 : {
                // TODO: Test this approach
                this.authService.logout()
                this.profileState.reset()
                //this.router.navigate(['/']);
                break;
              }
              default: {
                break;
              }
            }
          }
        }}
      )
    )
  }
}
