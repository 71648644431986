import { Address } from "@api/models/Address";
import { User } from "@api/models/User";
import { InsurerCountryDocument } from "@api/models/InsurerCountryDocument";
import { MembershipTier } from "@api/models/enums/MembershipTier";
import { Account } from "@api/models/Account";

export class Insurance {
  id?: number;
  name: string = "";
  creationDate!: string;
  modificationDate!: string;
  address: Address = new Address();
  account: Account = new Account();
  imageUrl?: string;
  note: string = "";
  users: User[] = [];
  certificates: InsurerCountryDocument[] = [];
  termsOfUse: InsurerCountryDocument[] = [];
  tier: MembershipTier = MembershipTier.BASIC;
}
