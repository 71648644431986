import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-layout-default',
  templateUrl: './layout-default.component.html',
  styleUrls: ['./layout-default.component.scss']
})
export class LayoutDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
